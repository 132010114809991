import { memo } from "react";

import "./style.scss";

// TODO remove it after refactoring
const Label = ({ name }: { name: string }) => {
  return (
    <div className="get-label">
      <span>{name}</span>
    </div>
  );
};

export default memo(Label);
