import { useCallback, useEffect, useMemo, useState } from "react";

import { useDebouncedCallback } from "use-debounce";

import { AutoComplete, AutoCompleteProps, Input } from "antd";
import { noop } from "helpers";

import { AutocompleteItem } from "./item";
import { DefaultOptionType } from "antd/lib/select";

import { Loading3QuartersOutlined } from "@ant-design/icons";

interface IProps extends AutoCompleteProps {
  debounceDelay?: number;
  minCharacterSearchCount?: number;
  selectable?: boolean;
  loading?: boolean;
  loadingSet?: Set<string>;
  items: OptionProps[];
  loadingContent?: AutoCompleteProps["notFoundContent"];
  onSearchValue: (
    value: string,
    { id, requestCount }: { id?: string; requestCount?: number },
  ) => void;
}

export interface OptionProps {
  value: string;
  title: string;
  subtitle?: string;
}

type HandleFunction<T> = (...args: T[]) => void;

const styles: { [key: string]: React.CSSProperties } = {
  iconContainer: {
    position: "absolute",
    right: 18,
    top: 7,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    color: "#8c8c8c",
  },
};

export const Autocomplete = ({
  id,
  onSearchValue = noop,
  debounceDelay = 510,
  minCharacterSearchCount = 3,
  selectable = false,
  loading = false,
  loadingSet = new Set(),
  notFoundContent = "Not found",
  loadingContent = "Loading...",
  items,
  ...props
}: IProps) => {
  const [isSelected, setIsSelected] = useState(!!props.value);
  const [init, setInit] = useState(true);

  const isLoading: boolean = id ? loadingSet.has(id) : loading;
  const idBody = useMemo(() => (id ? { id } : {}), [id]);

  const handleSearchDebounced = useDebouncedCallback((value: string) => {
    onSearchValue(value, idBody);
  }, debounceDelay);

  useEffect(() => {
    if (isSelected || init) {
      setInit(false);
      return;
    }

    if (!props.value?.length) {
      handleSearchDebounced("");
    }

    if (props.value?.length >= minCharacterSearchCount) {
      handleSearchDebounced(props.value);
    }
  }, [props.value, isSelected]);

  const handleSelect: HandleFunction<unknown> = useCallback(
    (...args) => {
      if (!props.onSelect) return;

      setIsSelected(true);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.onSelect(...args);
    },
    [props.onSelect],
  );

  const handleChange: HandleFunction<unknown> = useCallback(
    (...args) => {
      if (!props.onChange) return;

      setIsSelected(false);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props.onChange(...args);
    },
    [props.onChange],
  );

  const handleFocus: HandleFunction<unknown> = useCallback(
    (...args) => {
      // if we click by exist data => update results:
      if (isSelected) {
        onSearchValue("", idBody);
        onSearchValue(props.value, idBody);
        // if we click by empty field or not selected field with value length < 3 => clear results:
      } else if (!isSelected && props.value?.length < minCharacterSearchCount) {
        onSearchValue("", idBody);
        // if we click by not selected field with value length >= 3 characters => update results:
      } else if (
        !isSelected &&
        props.value?.length >= minCharacterSearchCount
      ) {
        onSearchValue("", idBody);
        onSearchValue(props.value, idBody);
      }

      if (props.onFocus) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.onFocus(...args);
      }
    },
    [props.onFocus, isSelected, onSearchValue, props.value, idBody],
  );

  const handleBlur: HandleFunction<unknown> = useCallback(
    (...args) => {
      if (selectable) {
        if (!isSelected) {
          handleChange("", {});
        }

        if (props.onBlur) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          props.onBlur(...args);
        }
      }
    },
    [props.onBlur, selectable, isSelected],
  );

  const renderItem = ({ value, title, subtitle }: OptionProps) => ({
    value,
    item: { value, title, subtitle },
    label: <AutocompleteItem title={title} subtitle={subtitle} />,
  });

  const mappedOptions: DefaultOptionType[] = useMemo(
    () => items?.map(renderItem),
    [items],
  );

  return (
    <AutoComplete
      style={{ width: "100%" }}
      {...props}
      onSelect={handleSelect}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      notFoundContent={isLoading ? loadingContent : notFoundContent}
      options={mappedOptions}
    >
      <Input.Password
        allowClear={!isLoading}
        visibilityToggle={{ visible: true }}
        iconRender={() => (
          <div style={styles.iconContainer}>
            {isLoading ? (
              <Loading3QuartersOutlined spin style={styles.loader} />
            ) : null}
          </div>
        )}
      />
    </AutoComplete>
  );
};
