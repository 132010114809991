import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Card,
  Input,
  message,
  notification,
  Select,
  Table,
  Button as IconButton,
  Alert,
} from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ERoles, IPond } from "pages/Ponds/types";
import { useMsal } from "@azure/msal-react";
import { changeUsers, loadedUsers } from "http/userApi";
import { generateUser, handleCopyText, modalError } from "helpers";
import { userColumns } from "const";
import { Users, UsersToDisplay } from "./types";
import { v4 as uuidv4 } from "uuid";
import { Autocomplete, OptionProps } from "common/Autocomplete";
import { useSearchAD } from "hooks/requests/useSearchAD";
import { useLogin } from "hooks/useLogin";
import Button from "common/Button";
import Tooltip from "common/Tooltip";

import { UserEmailTooltip } from "../Tooltips/UserEmailTooltip";
import { FolderTooltip } from "../Tooltips/FolderTooltip";
import { AccessLevelTooltip } from "../Tooltips/AccessLevelTooltip";

import "./style.scss";

const AccessUsers = ({
  bucket,
  onFetchBucket,
}: {
  bucket: IPond;
  onFetchBucket: () => Promise<unknown>;
}) => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const {
    results: userList,
    loadingSet: loadingUserSet,
    handleSearch: handleSearchUsers,
  } = useSearchAD({
    msalInstance,
    api: "/v2/ad/users",
  });

  const autocompleteUsers: OptionProps[] = useMemo(
    () =>
      userList.map(({ name, mail = "" }) => ({
        value: mail,
        title: name,
        subtitle: mail,
      })),
    [userList],
  );

  const [edit, setEdit] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [savedUsers, setSavedUsers] = useState<{
    [index: string]: { level: keyof typeof ERoles; prefix: "" }[];
  }>();

  const [usersCollection, setUsersCollection] = useState<UsersToDisplay[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);

      const result: {
        data: Users | null;
        error: null | string;
      } = await loadedUsers(bucket.name, msalInstance);

      if (result.data && !result.error) {
        setSavedUsers(result.data);
        setUsersCollection(generateUser(result.data));
      }

      setLoading(false);
    };

    getUsers();
  }, [bucket.name]);

  const switchToEdit = useCallback((block: string) => setEdit(block), []);

  const editCancel = useCallback(() => {
    if (savedUsers) {
      setUsersCollection(generateUser(savedUsers));
    }
    setEdit("");
  }, [savedUsers]);

  const handleAddSpecifyFolders = (itemKey: number) => {
    setUsersCollection(
      usersCollection.map(userItem =>
        userItem.key === itemKey
          ? {
              ...userItem,
              type: {
                ...userItem.type,
                value: [
                  ...userItem.type.value,
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
              },
            }
          : userItem,
      ),
    );
  };

  const handleAddUser = useCallback(
    () =>
      usersCollection.length
        ? setUsersCollection([
            ...usersCollection,
            {
              key: usersCollection[usersCollection.length - 1].key + 1,
              email: {
                name: "",
                itemKey:
                  usersCollection[usersCollection.length - 1].email.itemKey + 1,
              },
              select: {
                value: "all",
                itemKey:
                  usersCollection[usersCollection.length - 1].email.itemKey + 1,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey:
                  usersCollection[usersCollection.length - 1].type.itemKey + 1,
              },
            },
          ])
        : setUsersCollection([
            {
              key: 0,
              email: {
                name: "",
                itemKey: 0,
              },
              select: {
                value: "all",
                itemKey: 0,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey: 0,
              },
            },
          ]),
    [usersCollection],
  );

  const savePermissions = async () => {
    setLoading(true);

    const emailOptions =
      process.env.REACT_APP_ALLOW_DOMAINS.split(" ").join("|");
    const emailPattern = new RegExp(`(${emailOptions})$`);

    for (let i = 0; i < usersCollection.length; i++) {
      if (usersCollection[i].email.name === "") {
        setLoading(false);
        return notification.error({
          message:
            'The "User email" field is required. Make sure they are filled in.',
        });
      }

      if (!usersCollection[i].email.name.match(emailPattern)) {
        setLoading(false);
        return notification.error({
          message: "Error",
          description: `Some emails have invalid format or are not in the list of allowed domains (${process.env.REACT_APP_ALLOW_DOMAINS.split(
            " ",
          ).join(", ")})`,
        });
      }

      if (
        usersCollection[i].select.value === "specify" &&
        usersCollection[i].type.value.length === 1 &&
        usersCollection[i].type.value[0].prefix === ""
      ) {
        setLoading(false);
        return notification.error({
          message: 'The "Specify folders" field must not be empty',
        });
      }

      if (
        usersCollection[i] &&
        usersCollection[i + 1] &&
        usersCollection[i].email.name === usersCollection[i + 1].email.name
      ) {
        setLoading(false);
        return notification.error({
          message: "The user's email must be unique",
        });
      }
    }

    const users: {
      [index: string]: {
        level: keyof typeof ERoles;
      };
    } = {};

    usersCollection.forEach(item => {
      Object.assign(users, {
        [item.email.name]: item.type.value.map(
          ({ prefixKey, ...rest }) => rest,
        ),
      });
    });

    const result: {
      data: {
        [index: string]: {
          level: keyof typeof ERoles;
          prefix: string;
        }[];
      } | null;
      error: null | string;
    } = await changeUsers(bucket.name, users, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.error === "Can't delete the last owner") {
      notification.error({
        message: "Error",
        description: result.error,
        duration: 3,
      });
      setLoading(false);
      setEdit("");
      return;
    }

    if (result.data && !result.error) {
      await onFetchBucket();
      message.success({
        content: "Users are updated",
        duration: 2,
      });
      setEdit("");
    } else {
      // setUsersCollection(generateUser(savedUsers ? savedUsers : {}));
      modalError(result.error);
    }

    setLoading(false);
  };

  const handleInputValue = (
    inputValue: string,
    field: string,
    item: { name: string; itemKey: number },
  ) => {
    setUsersCollection(
      usersCollection.map(userItem =>
        userItem.key === item.itemKey
          ? {
              ...userItem,
              [field]: { itemKey: item.itemKey, name: inputValue },
            }
          : userItem,
      ),
    );
  };

  const handleChangePrefixInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: number) => {
      setUsersCollection(
        usersCollection.map(userItem =>
          userItem.key === key
            ? {
                ...userItem,
                type: {
                  ...userItem.type,
                  value: userItem.type.value.map(item =>
                    item.prefixKey === event.target.name
                      ? {
                          ...item,
                          prefix: event.target.value,
                        }
                      : item,
                  ),
                },
              }
            : userItem,
        ),
      );
    },
    [usersCollection],
  );

  const handleChangeFoldersSelect = useCallback(
    (value: string, itemKey: number) => {
      setUsersCollection(
        usersCollection.map(userItem =>
          userItem.key === itemKey
            ? {
                ...userItem,
                select: { value: value, itemKey },
                type: {
                  ...userItem.type,
                  value:
                    value === "all"
                      ? [
                          {
                            level: "ro",
                            prefix: "",
                            prefixKey: uuidv4(),
                          },
                        ]
                      : userItem.type.value,
                },
              }
            : userItem,
        ),
      );
    },
    [usersCollection],
  );

  const handleDelete = useCallback(
    (
      userKey: number,
      prefixKey: string,
      found: {
        level: "ro" | "owner" | "rw";
        prefix: string;
        prefixKey?: string | undefined;
      }[],
    ) => {
      if (found.length === 1) {
        const owners = usersCollection.filter(item =>
          item.type.value.find(elem => elem.level === "owner"),
        );

        if (owners.length === 1 && owners[0].key === userKey) {
          notification.error({
            message: "Error",
            description: "Can't delete the last owner",
          });
          return;
        }

        setUsersCollection(
          usersCollection.filter(userItem => userItem.key !== userKey),
        );

        return;
      }

      setUsersCollection(
        usersCollection.map(userItem =>
          userItem.key === userKey
            ? {
                ...userItem,
                type: {
                  ...userItem.type,
                  value: userItem.type.value.filter(
                    item => item.prefixKey !== prefixKey,
                  ),
                },
              }
            : userItem,
        ),
      );
    },
    [usersCollection],
  );

  const handleChangePrefixSelect = useCallback(
    (key: number, value: keyof typeof ERoles, prefixKey: string) => {
      setUsersCollection(
        usersCollection.map(userItem =>
          userItem.key === key
            ? {
                ...userItem,
                type: {
                  ...userItem.type,
                  value: userItem.type.value.map(item =>
                    item.prefixKey === prefixKey
                      ? {
                          ...item,
                          level: value,
                        }
                      : item,
                  ),
                },
              }
            : userItem,
        ),
      );
    },
    [usersCollection],
  );

  const handleChangeUser = useCallback(
    (record: { name: string; itemKey: number }) => (value: string) =>
      handleInputValue(value, "email", record),
    [handleInputValue],
  );

  const editableUserColumns = [
    {
      title: () => (
        <Tooltip title="User email" tooltip={<UserEmailTooltip />} />
      ),
      dataIndex: "email",
      render: (record: { name: string; itemKey: number }) => {
        return (
          <Autocomplete
            id={`${record.itemKey}`}
            value={record.name}
            items={autocompleteUsers}
            onChange={handleChangeUser(record)}
            onSearchValue={handleSearchUsers}
            onSelect={handleChangeUser(record)}
            loadingSet={loadingUserSet}
          />
        );
      },
      key: "email",
      width: "35%",
    },
    {
      title: () => (
        <Tooltip title="Folders access mode" tooltip={<FolderTooltip />} />
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        return (
          <Select
            className="column-folders-select"
            value={record.value}
            onChange={(value: string) =>
              handleChangeFoldersSelect(value, record.itemKey)
            }
          >
            <Select.Option value="all">All folders</Select.Option>
            <Select.Option value="specify">Specify folders</Select.Option>
          </Select>
        );
      },
      key: "select",
      width: "10%",
    },
    {
      title: () => (
        <div className="access-titles">
          <div>Pond folders</div>
          <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
          <div>Action</div>
        </div>
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        const foundItem = usersCollection.find(
          item => item.key === record.itemKey,
        );

        return (
          <>
            {record.value === "all" ? (
              <div className="access-fields-wrapper">
                <Input
                  name="all"
                  onChange={e => handleChangePrefixInput(e, record.itemKey)}
                  value={foundItem?.type.value[0].prefix}
                  disabled={record.value === "all"}
                />
                <Select
                  className="item-selector"
                  onChange={value =>
                    handleChangePrefixSelect(
                      record.itemKey,
                      value,
                      foundItem?.type.value[0].prefixKey
                        ? foundItem.type.value[0].prefixKey
                        : "",
                    )
                  }
                  value={foundItem?.type.value[0].level}
                >
                  <Select.Option value="owner">Owner</Select.Option>
                  <Select.Option value="ro">Read only</Select.Option>
                  <Select.Option value="rw">Read write</Select.Option>
                </Select>
                <div style={{ maxWidth: "fit-content" }}>
                  <IconButton
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      handleDelete(
                        record.itemKey,
                        foundItem?.type.value[0].prefixKey
                          ? foundItem.type.value[0].prefixKey
                          : "",
                        foundItem?.type.value ? foundItem?.type.value : [],
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              foundItem?.type.value.map(item => (
                <div className="access-fields-wrapper">
                  <Input
                    name={item.prefixKey}
                    onChange={e => handleChangePrefixInput(e, record.itemKey)}
                    value={item.prefix}
                    disabled={record.value === "all"}
                  />
                  <Select
                    className="item-selector"
                    onChange={value =>
                      handleChangePrefixSelect(
                        record.itemKey,
                        value,
                        item.prefixKey ? item.prefixKey : "",
                      )
                    }
                    value={item.level}
                  >
                    <Select.Option value="owner">Owner</Select.Option>
                    <Select.Option value="ro">Read only</Select.Option>
                    <Select.Option value="rw">Read write</Select.Option>
                  </Select>
                  <div style={{ maxWidth: "fit-content" }}>
                    <IconButton
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        handleDelete(
                          record.itemKey,
                          item.prefixKey ? item.prefixKey : "",
                          foundItem.type.value,
                        )
                      }
                    />
                  </div>
                </div>
              ))
            )}
            {record.value === "all" ? null : (
              <Button
                style={{
                  width: "100%",
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size="middle"
                type="default"
                onClick={() => handleAddSpecifyFolders(record.itemKey)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            )}
          </>
        );
      },
      key: "pondFolders",
      width: "55%",
    },
  ];

  return (
    <div className="access-pond">
      <Card
        title="Users"
        className="access-pond-section"
        extra={
          edit !== "users" ? (
            <Button
              type="primary"
              size="large"
              onClick={() => switchToEdit("users")}
              icon={<EditOutlined />}
              disabled={
                bucket.management_permissions.can_add_users ? false : true
              }
            >
              Edit
            </Button>
          ) : (
            <div className="edit-buttons">
              <Button className="cancel-btn" onClick={editCancel} size="large">
                Cancel
              </Button>
              <Button
                htmlType="submit"
                disabled={loading ? true : false}
                type="primary"
                size="large"
                onClick={savePermissions}
              >
                Save
              </Button>
            </div>
          )
        }
      >
        {bucket?.kyc_link && (
          <>
            <Alert
              description={
                <>
                  The link below can be used to invite external users as Guests
                  to Bayer Single Sign-On (Entra ID) tenant. The users will be
                  required to pass identity verification as part of{" "}
                  <a
                    href="https://bayergroup.sharepoint.com/sites/csrm/SitePages/Digital-Partner-Security.aspx"
                    target="_blank"
                  >
                    Digital Partner Security
                  </a>{" "}
                  process. See more about externals in Data Pond in the{" "}
                  <a
                    href="https://docs.int.bayer.com/cloud/smart-cloud-automation/atscale/data-at-scale/datapond/user_guide/#inviting-external-partners"
                    target="_blank"
                  >
                    user guide
                  </a>
                  .{" "}
                  <a
                    href="https://bayergroup.sharepoint.com/sites/csrm/SitePages/Digital-Partner-Security.aspx#2.-company-based-trust"
                    target="_blank"
                  >
                    Company-Based Trust
                  </a>{" "}
                  will also work.
                  <Button
                    style={{ marginTop: 8 }}
                    size="middle"
                    onClick={handleCopyText(
                      "Access Package link",
                      bucket.kyc_link,
                    )}
                    icon={<CopyOutlined />}
                  >
                    Copy Access Package link
                  </Button>
                </>
              }
              type="info"
              showIcon
            />
            <br />
          </>
        )}
        {edit === "users" ? (
          <>
            <Table
              dataSource={usersCollection}
              columns={editableUserColumns}
              loading={loading}
              pagination={false}
            />
            <Button
              type="dashed"
              disabled={loading}
              className="add-permissions-btn"
              icon={<PlusOutlined />}
              onClick={handleAddUser}
            >
              Add user
            </Button>
          </>
        ) : bucket.management_permissions.can_add_users ? (
          <Table
            columns={userColumns}
            dataSource={usersCollection}
            pagination={false}
            loading={loading}
          />
        ) : (
          <div className="disabled-block">
            <Table
              columns={userColumns}
              dataSource={usersCollection}
              pagination={false}
              loading={loading}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default memo(AccessUsers);
