import { memo } from "react";

import { Card, Form, Input, Select, Switch, Row, Col } from "antd";

import Tooltip from "common/Tooltip";

import { EEncryption } from "pages/Ponds/types";
import { DATA_CLASSIFICATION_LIST } from "const";

import { useRegions } from "hooks/requests/useRegions";

type IProps = {
  name: string;
  region: string;
  encryption: EEncryption | null;
  projectName: string;
  dataClassification: string;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRegion: (value: string) => void;
  onChangeEncryption: (event: boolean) => void;
  onChangeProjectName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeDataClassification: (value: string) => void;
};

const AddPondGeneral = ({
  name,
  region,
  encryption,
  projectName,
  dataClassification,
  onChangeName,
  onChangeRegion,
  onChangeEncryption,
  onChangeProjectName,
  onChangeDataClassification,
}: IProps) => {
  const { regions, isLoading } = useRegions();

  return (
    <Card title="General" bordered={false} className="add-pond-general">
      <Row gutter={[32, 8]}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Form.Item
            colon={false}
            name="pondName"
            rules={[
              {
                required: true,
                message: "The field is required.",
              },
              {
                pattern:
                  /(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{3,48}[a-z0-9]$/,
                message: "Format is not right.",
              },
            ]}
            label={
              <Tooltip
                title="Pond name"
                tooltip="The pond name should contain only lowercase letters, numbers and hyphens (can't be the first or the last symbol) (from 3 to 50 symbols)."
              />
            }
          >
            <Input
              name="policy"
              placeholder="Name"
              className="policy-id-input"
              onChange={onChangeName}
              value={name}
            />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Form.Item
            colon={false}
            name="region"
            rules={[
              {
                required: true,
                message: "The field is required.",
              },
            ]}
            label="Region"
            initialValue={region}
          >
            <Select
              className="select-field"
              placeholder="please select"
              onChange={onChangeRegion}
              value={region}
              loading={isLoading}
              disabled={isLoading}
            >
              {regions.map(region => (
                <Select.Option key={1} value={region}>
                  {region}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Form.Item
            colon={false}
            name="encryption"
            rules={[
              {
                required: true,
                message: "The field is required.",
              },
            ]}
            label="Advanced (KMS) encryption"
            initialValue={encryption}
          >
            <Switch onChange={onChangeEncryption} />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Form.Item
            colon={false}
            name="projectName"
            rules={[
              {
                required: true,
                message: "The field is required.",
              },
              {
                pattern: /[\w\d _@.-]+/,
                message: "Format is not right.",
              },
              {
                max: 32,
                message: "Maximum 32 characters.",
              },
            ]}
            label="Project name / BEAT ID"
          >
            <Input
              name="projectName"
              placeholder="Name"
              className="policy-id-input"
              onChange={onChangeProjectName}
              value={projectName}
            />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Form.Item
            colon={false}
            name="dataClassification"
            label={
              <Tooltip
                title="Data classification"
                tooltip={
                  <>
                    Information Classification according to Bayer standards, see
                    more at{" "}
                    <a href="https://go.cnb/ICL" target="_blank">
                      go/ICL
                    </a>
                  </>
                }
              />
            }
            initialValue={dataClassification}
          >
            <Select
              className="select-field"
              onChange={onChangeDataClassification}
              value={dataClassification}
            >
              {DATA_CLASSIFICATION_LIST.map(({ id, value, label, isVisible }) =>
                isVisible ? (
                  <Select.Option key={id} value={value}>
                    {label}
                  </Select.Option>
                ) : null,
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default memo(AddPondGeneral);
