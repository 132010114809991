import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Alert,
  Card,
  Descriptions,
  Empty,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
  Button as IconButton,
} from "antd";
import { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { bucketUrl } from "http/index";

import { addMachineUser } from "http/userApi";
import { ERoles } from "pages/Ponds/types";

import useFetch from "hooks/useFetch";
import { useLogin } from "hooks/useLogin";
import Button from "common/Button";
import Spinner from "common/Spinner";
import Label from "common/Label";
import Tooltip from "common/Tooltip";

import MachineUser from "../MachineUsers/index";
import { MachineUsers, MachineUsersSchema } from "./types";
import { StaticCredentialsTooltip } from "../Tooltips/StaticCredentialsTooltip";

import "./style.scss";

const AccessData = ({
  id,
  accessStatus,
  isOwner,
}: {
  id: string;
  accessStatus: boolean;
  isOwner: boolean;
}) => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [machineUsers, setMachineUsers] = useState<Array<MachineUsers>>([]);
  const [modalConfirm, setModalConfirm] = useState<{
    show: boolean;
    accessKey?: string;
    secretKey?: string;
    jdbcString?: string;
  }>();
  const [description, setDescription] = useState<string>("");
  const [expiration, setExpiration] = useState<string>("3");
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<string>("");
  const [prefixes, setPrefixes] = useState<
    {
      key: number;
      level: keyof typeof ERoles;
      prefix: string;
    }[]
  >([]);

  const [selectFolder, setSelectFolder] = useState("all");
  const [allFolder, setAllFolder] = useState<keyof typeof ERoles>("ro");

  const { data, isLoading, error, token, fetchData } = useFetch<MachineUsers[]>(
    `${bucketUrl}v2/s3buckets/${id}/machine-users`,
    msalInstance,
  );

  const labelStyle = useMemo(
    () => ({
      width: 200,
    }),
    [],
  );

  const clearForm = useCallback(() => {
    setExpiration("3");
    setPrefixes([]);
    setSelectFolder("all");
    setAllFolder("ro");
    setDescription("");
  }, [
    setExpiration,
    setPrefixes,
    setSelectFolder,
    setAllFolder,
    setDescription,
  ]);

  const handleChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value);
    },
    [setDescription],
  );

  useEffect(() => {
    if (token === "token expired") {
      showAuthorizationErrorModal();
    }

    if (data) {
      setMachineUsers(data);
    }
  }, [id, data, showAuthorizationErrorModal, token, error]);

  const handleExpirationSelectChange = useCallback((value: string) => {
    setExpiration(value);
  }, []);

  const handleChangePrefixSelect = useCallback(
    (value: string, prefixId: number) => {
      setPrefixes(
        prefixes.map(item =>
          item.key === prefixId
            ? {
                key: item.key,
                level: value === "ro" ? "ro" : value === "rw" ? "rw" : "owner",
                prefix: item.prefix,
              }
            : item,
        ),
      );
    },
    [prefixes],
  );

  const handleChangePrefixName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setPrefixes(
        prefixes.map(item =>
          item.key === +e.target.name
            ? {
                key: item.key,
                level: item.level,
                prefix: e.target.value,
              }
            : item,
        ),
      );
    },
    [prefixes],
  );

  const handleChangePermission = (value: string) => {
    setAllFolder(value as keyof typeof ERoles);
  };

  const handleDeletePrefix = useCallback(
    (prefixId: number) => {
      setPrefixes(prefixes.filter(item => item.key !== prefixId));
    },
    [prefixes],
  );

  const handleAddPrefix = useCallback(() => {
    setPrefixes(prevPrefixes => {
      if (prevPrefixes.length) {
        return [
          ...prevPrefixes,
          {
            key: prevPrefixes[prevPrefixes.length - 1].key + 1,
            level: "ro",
            prefix: "",
          },
        ];
      } else {
        return [{ key: 0, level: "ro", prefix: "" }];
      }
    });
  }, [prefixes]);

  const handleChangeFoldersSelect = useCallback(
    (value: string) => {
      setSelectFolder(value);

      if (value === "specify") {
        handleAddPrefix();
      }

      if (value === "all") {
        setPrefixes([]);
      }
    },
    [setSelectFolder, handleAddPrefix, setPrefixes],
  );

  const onGenerateMachineUser = async () => {
    setLoading("machine");

    if (selectFolder === "specify") {
      for (let i = 0; i < prefixes.length; i++) {
        if (prefixes[i].prefix === "") {
          setLoading("");
          return notification.error({
            message:
              'The "Name of folder" field is required. Make sure they are filled in.',
          });
        }
      }
    }

    const completedPrefixes = prefixes.map(({ key, ...rest }) => rest);

    const body: MachineUsersSchema = {
      valid: {
        unit: "month",
        value: +expiration,
      },
      description: description || "Static credentials",
      permissions:
        selectFolder === "all"
          ? [{ level: allFolder, prefix: "" }]
          : completedPrefixes,
    };

    const result: {
      data: AxiosResponse<MachineUsers> | null;
      error: null | string;
    } = await addMachineUser(body, id, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data) {
      setModalConfirm({
        show: true,
        accessKey: result.data.data.access_key_id,
        secretKey: result.data.data.secret_access_key,
        jdbcString: result.data.data.jdbc_connection_string,
      });
      setMachineUsers([...machineUsers, result.data.data]);
      setShowForm(false);
      clearForm();

      message.success({
        content: "Static credentials was generated successfully",
        duration: 2,
      });
    }

    if (result.error) {
      notification.error({
        message: "Static credentials issue",
        description: result.error,
        duration: 5,
      });
    }

    setLoading("");
  };

  const showAddMachineUserForm = useCallback(() => setShowForm(true), []);
  const closeAddMachineUserForm = useCallback(() => setShowForm(false), []);

  const handleCloseAddMachineUserForm = useCallback(() => {
    clearForm();
    closeAddMachineUserForm();
  }, [clearForm, closeAddMachineUserForm]);

  const handleCopyAccessKey = () => {
    if (modalConfirm?.accessKey) {
      navigator.clipboard.writeText(modalConfirm.accessKey);
      message.success({
        content: "Access key ID is copied",
        duration: 1,
      });
    } else {
      message.error({
        content: "Something wrong",
        duration: 1,
      });
    }
  };

  const handleCopySecretKey = () => {
    if (modalConfirm?.secretKey) {
      navigator.clipboard.writeText(modalConfirm.secretKey);
      message.success({
        content: "Secret Access Key is copied",
        duration: 1,
      });
    } else {
      message.error({
        content: "Something wrong",
        duration: 1,
      });
    }
  };

  const handleCopyJdbcString = () => {
    if (modalConfirm?.jdbcString) {
      navigator.clipboard.writeText(modalConfirm.jdbcString);
      message.success({
        content: "JDBC connection string is copied",
        duration: 1,
      });
    } else {
      message.error({
        content: "Something wrong",
        duration: 1,
      });
    }
  };

  const closeModal = useCallback(
    () =>
      setModalConfirm({
        show: false,
      }),
    [],
  );

  return (
    <>
      <Modal
        title="Secret access key"
        okText="I copied credentials"
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        closable={false}
        open={!!modalConfirm?.show}
        onOk={closeModal}
      >
        <Alert
          className="info-alert"
          message="Static credential generated. Before you close this dialog, copy it and save it in a secure location. This is the only time the Secret Access Key is visible."
          type="info"
          showIcon
        />
        <Descriptions column={1}>
          <Descriptions.Item
            className="descr-item"
            labelStyle={labelStyle}
            label={<Label name="Access key ID" />}
          >
            <div>
              {modalConfirm?.accessKey}
              <CopyOutlined
                className="copy-icon"
                onClick={handleCopyAccessKey}
              />
            </div>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item
            className="descr-item"
            labelStyle={labelStyle}
            label={<Label name="Secret Access Key" />}
          >
            ***********************************
            <CopyOutlined className="copy-icon" onClick={handleCopySecretKey} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item
            className="descr-item"
            labelStyle={labelStyle}
            label={<Label name="JDBC connection string" />}
          >
            {modalConfirm?.jdbcString ? (
              <>
                ***********************************
                <CopyOutlined
                  className="copy-icon"
                  onClick={handleCopyJdbcString}
                />
              </>
            ) : (
              "Athena is disabled"
            )}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
      <Card
        id="static-credentials"
        title={
          <Tooltip
            title="Static credentials"
            tooltip={<StaticCredentialsTooltip />}
          />
        }
        className="machine-users"
        extra={
          accessStatus && (
            <Button
              size="large"
              type="primary"
              onClick={showAddMachineUserForm}
              icon={<PlusOutlined />}
            >
              Add credentials
            </Button>
          )
        }
      >
        {isLoading ? (
          <Spinner
            size="default"
            backgroundStyle={{
              position: "absolute",
              top: "calc(50% + 24px)",
              left: "calc(50% - 10px)",
            }}
          />
        ) : machineUsers.length ? (
          accessStatus ? (
            machineUsers.map((item, index) => (
              <MachineUser
                key={item.id}
                item={item}
                index={index}
                setLoading={setLoading}
                setMachineUsers={setMachineUsers}
                id={id}
                isOwner={isOwner}
                loading={loading === "list"}
                onFetchMachineUsers={fetchData}
              />
            ))
          ) : (
            <div className="disabled-block">
              {machineUsers.map((item, index) => (
                <MachineUser
                  key={item.id}
                  item={item}
                  index={index}
                  setLoading={setLoading}
                  setMachineUsers={setMachineUsers}
                  id={id}
                  isOwner={isOwner}
                  loading={loading === "list"}
                />
              ))}
            </div>
          )
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card>
      <Modal
        title="Add credentials"
        open={showForm}
        onCancel={handleCloseAddMachineUserForm}
        okText="Generate"
        onOk={onGenerateMachineUser}
        confirmLoading={loading === "machine"}
        cancelButtonProps={{
          disabled: loading === "machine",
        }}
      >
        <Form className="machine-user-wrapper">
          <div className="machine-user-form">
            <Form.Item label="Description">
              <Input
                className="input-field"
                value={description}
                onChange={handleChangeDescription}
              />
            </Form.Item>
            <Form.Item label="Expiration">
              <Select
                value={expiration}
                placeholder="12 months"
                onChange={handleExpirationSelectChange}
                className="select"
              >
                <Select.Option value="1">1 month</Select.Option>
                <Select.Option value="3">3 months</Select.Option>
                <Select.Option value="6">6 months</Select.Option>
              </Select>
            </Form.Item>
            <div style={{ display: "flex", gap: 16 }}>
              <Select
                value={selectFolder}
                size="large"
                onChange={handleChangeFoldersSelect}
                style={{ width: "60%" }}
              >
                <Select.Option value="all">All folders</Select.Option>
                <Select.Option value="specify">Specify folders</Select.Option>
              </Select>
              {selectFolder === "specify" ? (
                <div>
                  <Button
                    type="dashed"
                    className="add-folder-btn"
                    size="large"
                    onClick={handleAddPrefix}
                  >
                    Add folder
                  </Button>
                </div>
              ) : (
                <Select
                  size="large"
                  value={allFolder}
                  onChange={handleChangePermission}
                  style={{ width: "40%" }}
                >
                  <Select.Option value="ro">Read only</Select.Option>
                  <Select.Option value="rw">Read-write</Select.Option>
                </Select>
              )}
            </div>
          </div>
          {prefixes.length ? (
            <div className="prefix-wrapper">
              {prefixes.map((item, index) => (
                <div key={index} className="prefix-wrapper__item">
                  <Input
                    name={item.key.toString()}
                    onChange={handleChangePrefixName}
                    placeholder="Name of folder"
                  />
                  <Select
                    onChange={(value: string) =>
                      handleChangePrefixSelect(value, item.key)
                    }
                    value={item.level}
                  >
                    <Select.Option value="ro">Read only</Select.Option>
                    <Select.Option value="rw">Read-write</Select.Option>
                  </Select>
                  <IconButton
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeletePrefix(item.key)}
                    disabled={prefixes.length === 1}
                  />
                </div>
              ))}
            </div>
          ) : null}
          <Button
            loading={loading === "machine"}
            style={{ display: "none" }}
            type="primary"
            size="large"
            onClick={onGenerateMachineUser}
          >
            Generate
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default memo(AccessData);
