import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Card,
  Input,
  message,
  notification,
  Select,
  Table,
  Button as IconButton,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ERoles, IPond } from "pages/Ponds/types";
import { useMsal } from "@azure/msal-react";
import { changeGroups, loadedGroups } from "http/userApi";
import { generateGroup, modalError } from "helpers";
import { groupColumns } from "const";
import { Group, GroupsToDisplay } from "./types";
import { v4 as uuidv4 } from "uuid";

import Button from "common/Button";
import Tooltip from "common/Tooltip";
import { Autocomplete, OptionProps } from "common/Autocomplete";
import { useSearchAD } from "hooks/requests/useSearchAD";
import { useLogin } from "hooks/useLogin";

import { AccessLevelTooltip } from "../Tooltips/AccessLevelTooltip";
import { GroupNameTooltip } from "../Tooltips/GroupNameTooltip";
import { FolderTooltip } from "../Tooltips/FolderTooltip";
import { GroupsTooltip } from "../Tooltips/GroupsTooltip";

const AccessGroups = ({ bucket }: { bucket: IPond }) => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const {
    results: groupList,
    loadingSet: loadingGroupSet,
    handleSearch: handleSearchGroups,
  } = useSearchAD({
    msalInstance,
    api: "/v2/ad/groups",
  });

  const autocompleteGroups: OptionProps[] = useMemo(
    () =>
      groupList.map(({ id, name }) => ({
        value: id,
        title: name,
        subtitle: id,
      })),
    [groupList],
  );

  const [edit, setEdit] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [savedGroups, setSavedGroups] = useState<Group[]>();

  const [groupsCollection, setGroupsCollection] = useState<GroupsToDisplay[]>(
    [],
  );

  useEffect(() => {
    const getGroups = async () => {
      setLoading(true);

      const result: {
        data: Group[] | null;
        error: null | string;
      } = await loadedGroups(bucket.name, msalInstance);

      if (result.data && !result.error) {
        setSavedGroups(result.data);
        setGroupsCollection(generateGroup(result.data));
      }

      setLoading(false);
    };

    getGroups();
  }, [bucket.name]);

  const switchToEdit = useCallback((block: string) => setEdit(block), []);

  const editCancel = useCallback(() => {
    if (savedGroups) {
      setGroupsCollection(generateGroup(savedGroups));
    }
    setEdit("");
  }, [savedGroups]);

  const handleAddGroup = useCallback(
    () =>
      groupsCollection.length
        ? setGroupsCollection(prevGroupsCollection => [
            ...prevGroupsCollection,
            {
              key:
                prevGroupsCollection[prevGroupsCollection.length - 1].key + 1,
              name: {
                id: "",
                name: "",
                itemKey:
                  prevGroupsCollection[prevGroupsCollection.length - 1].name
                    .itemKey + 1,
              },
              select: {
                value: "all",
                itemKey:
                  prevGroupsCollection[prevGroupsCollection.length - 1].name
                    .itemKey + 1,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey:
                  prevGroupsCollection[prevGroupsCollection.length - 1].type
                    .itemKey + 1,
              },
            },
          ])
        : setGroupsCollection([
            {
              key: 0,
              name: {
                id: "",
                name: "",
                itemKey: 0,
              },
              select: {
                value: "all",
                itemKey: 0,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey: 0,
              },
            },
          ]),
    [groupsCollection],
  );

  const savePermissions = async () => {
    setLoading(true);

    for (let i = 0; i < groupsCollection.length; i++) {
      if (!groupsCollection[i].name.id) {
        setLoading(false);
        return notification.error({
          message:
            'The "Group name" field is required. Make sure they are filled in.',
        });
      }

      if (
        groupsCollection[i].select.value === "specify" &&
        groupsCollection[i].type.value.length === 1 &&
        groupsCollection[i].type.value[0].prefix === ""
      ) {
        setLoading(false);
        return notification.error({
          message: 'The "Specify folders" field must not be empty',
        });
      }

      if (
        groupsCollection[i] &&
        groupsCollection[i + 1] &&
        groupsCollection[i].name.id === groupsCollection[i + 1].name.id
      ) {
        setLoading(false);
        return notification.error({
          message: "The groups's name must be unique",
        });
      }
    }

    const groups: {
      [index: string]: {
        level: keyof typeof ERoles;
      };
    } = {};

    groupsCollection.forEach(item => {
      Object.assign(groups, {
        [item.name.id]: item.type.value.map(({ prefixKey, ...rest }) => rest),
      });
    });

    const result: {
      data: Group[] | null;
      error: null | string;
    } = await changeGroups(bucket.name, groups, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.error === "Can't delete the last owner") {
      notification.error({
        message: "Error",
        description: result.error,
        duration: 3,
      });
      setLoading(false);
      setEdit("");
      return;
    }

    if (result.data && !result.error) {
      message.success({
        content: "Groups are updated",
        duration: 2,
      });
      setEdit("");
    } else {
      // setGroupsCollection(generateGroup(savedGroups ? savedGroups : {}));
      modalError(result.error);
    }

    setLoading(false);
  };

  const handleChangeFoldersSelect = useCallback(
    (value: string, itemKey: number) => {
      setGroupsCollection(
        groupsCollection.map(groupItem =>
          groupItem.key === itemKey
            ? {
                ...groupItem,
                select: { value: value, itemKey },
                type: {
                  ...groupItem.type,
                  value:
                    value === "all"
                      ? [
                          {
                            level: "ro",
                            prefix: "",
                            prefixKey: uuidv4(),
                          },
                        ]
                      : groupItem.type.value,
                },
              }
            : groupItem,
        ),
      );
    },
    [groupsCollection],
  );

  const handleGroupInputValue = (
    inputValue: string,
    field: string,
    item: { id: string; name: string; itemKey: number },
  ) => {
    setGroupsCollection(prevGroupsCollection =>
      prevGroupsCollection.map(groupItem =>
        groupItem.key === item.itemKey
          ? {
              ...groupItem,
              [field]: { itemKey: item.itemKey, name: inputValue, id: item.id },
            }
          : groupItem,
      ),
    );
  };

  const handleDelete = useCallback(
    (
      groupKey: number,
      prefixKey: string,
      found: {
        level: "ro" | "owner" | "rw";
        prefix: string;
        prefixKey?: string | undefined;
      }[],
    ) => {
      if (found.length === 1) {
        setGroupsCollection(
          groupsCollection.filter(groupItem => groupItem.key !== groupKey),
        );

        return;
      }

      setGroupsCollection(
        groupsCollection.map(groupItem =>
          groupItem.key === groupKey
            ? {
                ...groupItem,
                type: {
                  ...groupItem.type,
                  value: groupItem.type.value.filter(
                    item => item.prefixKey !== prefixKey,
                  ),
                },
              }
            : groupItem,
        ),
      );
    },
    [groupsCollection],
  );

  const handleChangePrefixSelect = useCallback(
    (key: number, value: keyof typeof ERoles, prefixKey: string) => {
      setGroupsCollection(
        groupsCollection.map(groupItem =>
          groupItem.key === key
            ? {
                ...groupItem,
                type: {
                  ...groupItem.type,
                  value: groupItem.type.value.map(item =>
                    item.prefixKey === prefixKey
                      ? {
                          ...item,
                          level: value,
                        }
                      : item,
                  ),
                },
              }
            : groupItem,
        ),
      );
    },
    [groupsCollection],
  );

  const handleChangePrefixInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: number) => {
      setGroupsCollection(
        groupsCollection.map(groupItem =>
          groupItem.key === key
            ? {
                ...groupItem,
                type: {
                  ...groupItem.type,
                  value: groupItem.type.value.map(item =>
                    item.prefixKey === event.target.name
                      ? {
                          ...item,
                          prefix: event.target.value,
                        }
                      : item,
                  ),
                },
              }
            : groupItem,
        ),
      );
    },
    [groupsCollection],
  );

  const handleAddSpecifyFolders = (itemKey: number) => {
    setGroupsCollection(
      groupsCollection.map(groupItem =>
        groupItem.key === itemKey
          ? {
              ...groupItem,
              type: {
                ...groupItem.type,
                value: [
                  ...groupItem.type.value,
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
              },
            }
          : groupItem,
      ),
    );
  };

  const handleChangeGroup = useCallback(
    (record: { name: string; itemKey: number }) =>
      (inputValue: string, data: { item: OptionProps; value: string }) => {
        handleGroupInputValue(data?.item?.title || inputValue, "name", {
          ...record,
          id: data?.value,
        });
      },
    [handleGroupInputValue],
  );

  const editableGroupColumns = [
    {
      title: () => (
        <Tooltip title="Group name" tooltip={<GroupNameTooltip />} />
      ),
      dataIndex: "name",
      render: (record: { id: string; name: string; itemKey: number }) => {
        return (
          <Autocomplete
            selectable
            id={`${record.itemKey}`}
            value={record.name}
            items={autocompleteGroups}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={handleChangeGroup(record)}
            onSearchValue={handleSearchGroups}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSelect={handleChangeGroup(record)}
            loadingSet={loadingGroupSet}
          />
        );
      },
      key: "name",
      width: "35%",
    },
    {
      title: () => (
        <Tooltip title="Folders access mode" tooltip={<FolderTooltip />} />
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        return (
          <Select
            className="column-folders-select"
            value={record.value}
            onChange={(value: string) =>
              handleChangeFoldersSelect(value, record.itemKey)
            }
          >
            <Select.Option value="all">All folders</Select.Option>
            <Select.Option value="specify">Specify folders</Select.Option>
          </Select>
        );
      },
      key: "select",
      width: "10%",
    },
    {
      title: () => (
        <div className="access-titles">
          <div>Pond folders</div>
          <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
          <div>Action</div>
        </div>
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        const foundItem = groupsCollection.find(
          item => item.key === record.itemKey,
        );

        return (
          <>
            {foundItem?.type.value.map(item => (
              <div className="access-fields-wrapper">
                <Input
                  name={item.prefixKey}
                  onChange={e => handleChangePrefixInput(e, record.itemKey)}
                  value={item.prefix}
                  disabled={record.value === "all"}
                />
                <Select
                  className="item-selector"
                  onChange={value =>
                    handleChangePrefixSelect(
                      record.itemKey,
                      value,
                      item.prefixKey ? item.prefixKey : "",
                    )
                  }
                  value={item.level}
                >
                  <Select.Option value="ro">Read only</Select.Option>
                  <Select.Option value="rw">Read write</Select.Option>
                </Select>
                <div style={{ maxWidth: "fit-content" }}>
                  <IconButton
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      handleDelete(
                        record.itemKey,
                        item.prefixKey ? item.prefixKey : "",
                        foundItem.type.value,
                      )
                    }
                  />
                </div>
              </div>
            ))}
            {record.value === "all" ? null : (
              <Button
                style={{
                  width: "100%",
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size="middle"
                type="default"
                onClick={() => handleAddSpecifyFolders(record.itemKey)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            )}
          </>
        );
      },
      key: "pondFolders",
      width: "55%",
    },
  ];

  return (
    <div className="access-pond access-group">
      <Card
        title={<Tooltip title="Groups" tooltip={<GroupsTooltip />} />}
        className="access-pond-section"
        extra={
          edit !== "groups" ? (
            <Button
              type="primary"
              size="large"
              onClick={() => switchToEdit("groups")}
              icon={<EditOutlined />}
              disabled={
                bucket.management_permissions.can_add_groups ? false : true
              }
            >
              Edit
            </Button>
          ) : (
            <div className="edit-buttons">
              <Button className="cancel-btn" size="large" onClick={editCancel}>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                disabled={loading ? true : false}
                type="primary"
                size="large"
                onClick={savePermissions}
              >
                Save
              </Button>
            </div>
          )
        }
      >
        {edit === "groups" ? (
          <>
            <Table
              loading={loading}
              dataSource={groupsCollection}
              columns={editableGroupColumns}
              pagination={false}
            />
            <Button
              type="dashed"
              disabled={loading}
              className="add-permissions-btn"
              icon={<PlusOutlined />}
              onClick={handleAddGroup}
            >
              Add group
            </Button>
          </>
        ) : bucket.management_permissions.can_add_groups ? (
          <Table
            columns={groupColumns}
            dataSource={groupsCollection}
            pagination={false}
            loading={loading}
          />
        ) : (
          <div className="disabled-block">
            <Table
              columns={groupColumns}
              dataSource={groupsCollection}
              pagination={false}
              loading={loading}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default memo(AccessGroups);
