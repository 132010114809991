import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { Button, Card, Descriptions, Popover, Row } from "antd";
import Label from "common/Label";
import { SSHKey } from "components/Users/components/AccessData/types";
import { formatDateTime, handleCopyText } from "helpers";

import { useCallback, useMemo, useState } from "react";

export const SSHKeyItem = ({
  item,
  loading,
  isDeletingSHHKey,
  onDeleteSHHKey,
}: {
  item: SSHKey;
  loading: boolean;
  isDeletingSHHKey: boolean;
  onDeleteSHHKey: (id: string) => void;
}) => {
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] =
    useState(false);

  const openDeleteConfirmation = useCallback(() => {
    setIsOpenDeleteConfirmation(true);
  }, [setIsOpenDeleteConfirmation]);

  const closeDeleteConfirmation = useCallback(() => {
    setIsOpenDeleteConfirmation(false);
  }, [setIsOpenDeleteConfirmation]);

  const handleDeleteSHHKey = useCallback(() => {
    closeDeleteConfirmation();
    onDeleteSHHKey(item.id);
  }, [onDeleteSHHKey, item.id, closeDeleteConfirmation]);

  const SHHKeyDeleteConfirmation = useMemo(
    () => (
      <div className="popup">
        <div className="popup-question">
          <ExclamationCircleOutlined />
          <span>Are you sure you want to delete this SHH key?</span>
        </div>
        <div className="popup-buttons">
          <Button
            type="default"
            size="small"
            onClick={closeDeleteConfirmation}
            loading={isDeletingSHHKey}
          >
            No
          </Button>
          <Button
            type="primary"
            size="small"
            loading={isDeletingSHHKey}
            onClick={handleDeleteSHHKey}
          >
            Yes
          </Button>
        </div>
      </div>
    ),
    [isDeletingSHHKey, handleDeleteSHHKey, closeDeleteConfirmation],
  );

  return (
    <Card
      key={item.id}
      title={item.description}
      className="machine-users-item-ssh"
      extra={
        <div style={{ display: "flex", gap: 16 }}>
          <Popover
            content={SHHKeyDeleteConfirmation}
            trigger="click"
            placement="left"
            open={isOpenDeleteConfirmation}
            onOpenChange={openDeleteConfirmation}
          >
            <Button
              danger
              shape="circle"
              icon={<DeleteOutlined />}
              loading={loading || isDeletingSHHKey}
            />
          </Popover>
        </div>
      }
    >
      <Row>
        <Descriptions column={2}>
          <Descriptions.Item label={<Label name="Created date" />}>
            {formatDateTime(item.created_on)}
          </Descriptions.Item>
          <Descriptions.Item label={<Label name="Created by" />}>
            {item.created_by}
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row>
        <Descriptions column={2}>
          <Descriptions.Item label={<Label name="Expiration date" />}>
            {formatDateTime(item.valid_until)}
          </Descriptions.Item>
          <Descriptions.Item label={<Label name="Public key" />}>
            <>
              <span className="ellipsis">{item.public_key}</span>
              <CopyOutlined
                className="copy-icon"
                onClick={handleCopyText("Public key", item.public_key)}
              />
            </>
          </Descriptions.Item>
        </Descriptions>
      </Row>
    </Card>
  );
};
