import { memo } from "react";
import { Empty, Space, Spin } from "antd";

import "./style.scss";

const Spinner = ({
  className,
  size = "large",
  backgroundStyle,
}: {
  className?: string;
  size?: "large" | "small" | "default";
  backgroundStyle?: React.CSSProperties;
}) => {
  return (
    <div className={`spinner ${className ? className : ""}`}>
      {backgroundStyle && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      <Space size="middle">
        <Spin
          size={size}
          {...(backgroundStyle && { style: backgroundStyle })}
        />
      </Space>
    </div>
  );
};

export default memo(Spinner);
