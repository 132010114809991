import { memo } from "react";

import { Card, Switch } from "antd";

import Archive from "assets/img/Archive.png";
import Colid from "assets/img/Colid.png";
import Athena from "assets/img/Athena.png";

import { Colid as ColidType } from "pages/Ponds/types";

type IProps = {
  archive: boolean;
  athena: boolean;
  colid: ColidType;
  onChangeArchive: () => void;
  onChangeAthena: () => void;
  onChangeColid: () => void;
};

const AddPondAdditional = ({
  archive,
  athena,
  colid,
  onChangeArchive,
  onChangeAthena,
  onChangeColid,
}: IProps) => {
  return (
    <Card
      title="Additional options"
      className="add-pond-additional"
      bordered={false}
      bodyStyle={{
        display: "flex",
      }}
    >
      <Card
        title="Archive"
        bordered={true}
        className="additional-item"
        extra={<Switch checked={archive} onChange={onChangeArchive} />}
        bodyStyle={{
          display: "flex",
        }}
        headStyle={{
          background: archive ? "#F0F5FF" : "white",
        }}
      >
        <img src={Archive} alt="" className="pond-img" />
        <div>
          <p>
            Move objects to S3 Glacier Deep Archive tier automatically after 6
            months of inactivity.
          </p>
          <a
            href="https://aws.amazon.com/blogs/aws/archive-s3-to-glacier/"
            target="_blank"
          >
            Learn more
          </a>
        </div>
      </Card>
      <Card
        title="Athena"
        bordered={true}
        className="additional-item"
        extra={<Switch checked={athena} onChange={onChangeAthena} />}
        bodyStyle={{
          display: "flex",
        }}
        headStyle={{
          background: athena ? "#F0F5FF" : "white",
        }}
      >
        <img src={Athena} alt="" className="pond-img" />
        <div>
          <p>
            Create Athena workgroup and Glue crawler, which will automatically
            scan CSV or Parquet files in the bucket and generate table schema.
            For best results, we recommend creating a folder per table and
            uploading files in each folder without additional nesting levels. It
            is not recommended to put the files into the bucket root.
          </p>
          <a
            href="https://aws.amazon.com/athena/?nc1=h_ls&whats-new-cards.sort-by=item.additionalFields.postDateTime&whats-new-cards.sort-order=desc"
            target="_blank"
          >
            Learn more
          </a>
        </div>
      </Card>
      <Card
        title="Register in COLID"
        bordered={true}
        className="additional-item last"
        extra={<Switch checked={!!colid} onChange={onChangeColid} />}
        bodyStyle={{
          display: "flex",
        }}
        headStyle={{
          background: !!colid ? "#F0F5FF" : "white",
        }}
      >
        <img src={Colid} alt="" className="colid" />
        <div>
          <p>
            COLID is a company-wide repository of data assets which allows to
            make your data discoverable by Bayer colleagues.
          </p>
          <a
            href="https://bayer-group.github.io/COLID-Documentation"
            target="_blank"
          >
            Learn more
          </a>
        </div>
      </Card>
    </Card>
  );
};

export default memo(AddPondAdditional);
