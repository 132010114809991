import { CSSProperties, ReactNode } from "react";
import { Checkbox } from "antd";

import BayerLogo from "assets/img/bayer-logo.png";
import { PrivacyPolicyLink } from "common/PrivacyPolicyLink";

import "./style.scss";

const CheckboxLine = ({
  style,
  children,
}: {
  style?: CSSProperties;
  children?: ReactNode;
}) => (
  <div className="terms-of-use__checkbox" style={{ ...style }}>
    <Checkbox checked />
    {children}
  </div>
);

export const TermsOfUse = () => {
  return (
    <>
      <header className="terms-of-use__header">
        <h3>Terms of Use</h3>
        <img src={BayerLogo}></img>
      </header>
      <CheckboxLine>
        The owner of a Pond is responsible to ensure compliance with all
        applicable regulations for the stored data. The Data Pond team does not
        monitor the data and does not manage the data stored in the Ponds.
      </CheckboxLine>
      <CheckboxLine>
        This instance of Data Pond is NOT qualified to store data classified as
        ‘Secret’.
      </CheckboxLine>
      <CheckboxLine>
        Data Pond stores its users emails for authentication purposes. This
        information is stored and used according{" "}
        <PrivacyPolicyLink label="Data Pond Privacy Policy" />.
      </CheckboxLine>
    </>
  );
};
