import { CopyOutlined } from "@ant-design/icons";

import { handleCopyText } from "helpers";

import "./style.scss";

type Props = {
  title?: string;
  code: string[];
};

export const CodeViewer = ({ title = "bash", code }: Props) => {
  return (
    <div className="code-viewer__container">
      <div className="code-viewer__header">
        <span>{title}</span>
        <a onClick={handleCopyText("Code", code.join("\n"))}>
          <CopyOutlined /> Copy code
        </a>
      </div>
      <div className="code-viewer__body">
        {code.map((line, idx) => (
          <code key={idx} className="code-viewer__body-text">
            {line}
          </code>
        ))}
      </div>
    </div>
  );
};
