import { useCallback, useRef } from "react";
import { Alert, Button, Form, Input, Modal, ModalProps, Row } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "../../style.scss";

const ValidationRules = {
  requiredField: {
    required: true,
    message: "The field is required.",
  },
};

const initialValues = {
  description: "",
  public_key: "",
};

export const SSHKeyModalForm = ({
  isCreatingSHHKey,
  open,
  onCancel,
  onSubmitSSHKey,
  showAlert,
}: ModalProps & {
  isCreatingSHHKey: boolean;
  onCancel: () => void;
  onSubmitSSHKey: (
    DTO: { description: string; public_key: string },
    onCloseForm: () => void,
  ) => void;
  showAlert?: boolean;
}) => {
  const [form] = Form.useForm();

  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const handleCloseForm = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form.resetFields, onCancel]);

  const handleSubmit = useCallback(
    (DTO: typeof initialValues) => {
      onSubmitSSHKey(DTO, handleCloseForm);
    },
    [onSubmitSSHKey, handleCloseForm],
  );

  const handleClickOk = useCallback(() => {
    if (buttonSubmitRef.current) {
      buttonSubmitRef.current.click();
    }
  }, []);

  return (
    <Modal
      title="Add a new SSH public key"
      open={open}
      onCancel={handleCloseForm}
      onOk={handleClickOk}
      okText="Add key"
      confirmLoading={isCreatingSHHKey}
      cancelButtonProps={{
        disabled: isCreatingSHHKey,
      }}
    >
      <Form
        className="shh-key-form"
        name="shh-key-form"
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
      >
        {showAlert && (
          <Alert
            className="shh-key-form__item-alert"
            description="Due to existing limitations of AWS SFTP server implementation, the user must have read access to the whole bucket in order to list bucket contents correctly. If the user only has access to a particular prefix, it's possible that SFTP client will fail to list bucket root directory with 'Permission Denied' error. The user will still be able to upload or download the files using full paths."
            type="error"
            showIcon
            icon={<ExclamationCircleOutlined />}
          />
        )}
        <Row>
          <Form.Item
            className="shh-key-form__item"
            label="Description"
            colon={false}
            name="description"
            rules={[ValidationRules.requiredField]}
          >
            <Input name="description" />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            className="shh-key-form__item"
            label="Public Key"
            colon={false}
            name="public_key"
            rules={[ValidationRules.requiredField]}
          >
            <Input name="public_key" />
          </Form.Item>
        </Row>
        <Button
          ref={buttonSubmitRef}
          style={{ display: "none" }}
          htmlType="submit"
        />
      </Form>
    </Modal>
  );
};
