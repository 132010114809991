import { Link } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";
import { Tag, message } from "antd";
import type { ColumnsType } from "antd/es/table";

import { EEncryption, ERoles, IPond } from "../pages/Ponds/types";
import Tooltip from "../common/Tooltip";

import { arrayToMapByField, getRolesFromKey } from "helpers";
import { UserEmailTooltip } from "components/Users/components/Tooltips/UserEmailTooltip";
import { FolderTooltip } from "components/Users/components/Tooltips/FolderTooltip";
import { AccessLevelTooltip } from "components/Users/components/Tooltips/AccessLevelTooltip";
import { GroupNameTooltip } from "components/Users/components/Tooltips/GroupNameTooltip";
import { RepositoryNameTooltip } from "components/Users/components/Tooltips/RepositoryNameTooltip";
import { AzureApplicationIdTooltip } from "components/Users/components/Tooltips/AzureApplicationIdTooltip";

export interface PondsTableRow {
  name: string;
  region: string;
  role: string;
  files: number;
  features: JSX.Element[] | string;
  actions: JSX.Element;
}

export const isDevelopment = process.env.NODE_ENV === "development";
export const isAllowSecretData =
  process.env.REACT_APP_ALLOW_SECRET_DATA?.toLowerCase() === "true";

export const FEEDBACK_URL = "https://forms.office.com/e/JaXXTxyyav";
export const USER_GUIDE_URL =
  "https://docs.int.bayer.com/cloud/smart-cloud-automation/atscale/data-at-scale/datapond/user_guide/";
export const TEAM_CHANNEL_URL =
  "https://teams.microsoft.com/l/channel/19%3a7CPIxhNYJQei-8x_wIxdh4MjaxWefdFSz5BSl2ksqhI1%40thread.tacv2/General?groupId=113a784c-eca0-4233-95d8-4add9ab5742a&tenantId=fcb2b37b-5da0-466b-9b83-0014b67a7c78";
export const MAIL_TO_URL = "mailto:datapond-support@bayer.com";
export const IMPRINT_URL = "https://www.bayer.com/en/imprint";
export const COLID_URL = `${process.env.REACT_APP_COLID_URL}/resource?pidUri=`;
export const SFTP_URL = process.env.REACT_APP_SFTP_HOSTNAME;

const handleCopyJSON = (name: string) => {
  navigator.clipboard.writeText(name);
  message.success({
    content: "Pond name is copied",
    duration: 1,
  });
};

export const PONDS_TABLE_COLUMNS: (params: {
  regions: string[];
}) => ColumnsType<PondsTableRow> = ({ regions }) => [
  {
    title: "Pond ID",
    dataIndex: "name",
    key: "name",
    width: "299px",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (value: string) => (
      <div className="name">
        <Link to={`/${value}`}>{value}</Link>
        <CopyOutlined onClick={() => handleCopyJSON(value)} />
      </div>
    ),
  },
  {
    title: "Region",
    dataIndex: "region",
    key: "region",
    width: "166px",
    onFilter: (value, record) => record.region.indexOf(value as string) === 0,
    filters: regions.map(region => ({
      text: region,
      value: region,
    })),
  },
  {
    title: "My role",
    dataIndex: "role",
    key: "role",
    width: "133px",
    filters: [
      {
        text: "Owner",
        value: "owner",
      },
      {
        text: "Read-write",
        value: "rw",
      },
      {
        text: "Read only",
        value: "ro",
      },
    ],
    onFilter: (value, record) =>
      ERoles[value as keyof typeof ERoles] === record.role,
  },
  {
    title: "Storage used",
    dataIndex: "storage",
    key: "storage",
    width: "100px",
  },
  {
    title: "Objects",
    dataIndex: "files",
    key: "files",
    render: (record: number) => (record <= 0 ? "-" : record),
    width: "100px",
    sorter: (a, b) => a.files - b.files,
  },
  {
    title: "Features",
    dataIndex: "features",
    key: "features",
    width: "176px",
    filters: [
      {
        text: "Athena",
        value: "Athena",
      },
      {
        text: "Archive",
        value: "Archive",
      },
      {
        text: "COLID",
        value: "COLID",
      },
    ],
    onFilter: (value, record) => {
      return Array.isArray(record.features)
        ? !!record.features.find(
            (item: {
              props: {
                children: string;
              };
            }) => item.props.children === value,
          )
        : false;
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    width: "87px",
  },
];

export const pondBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Ponds list",
  },
  {
    path: "/:id",
    key: 2,
    breadcrumbName: "",
  },
];

export const AUDIT_LOG_COLUMNS = [
  {
    title: "Time, UTC",
    dataIndex: "dateTime",
    key: "dateTime",
    width: "178px",
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    width: "271px",
  },
  {
    title: "Action",
    dataIndex: "event",
    key: "event",
    width: "271px",
  },
  {
    title: "Status",
    dataIndex: "decision",
    key: "decision",
    render: (decision: string) => {
      if (decision === "Permit")
        return <div className="audit-log-status success">Success</div>;
      if (decision === "Deny")
        return <div className="audit-log-status error">Error</div>;
    },
    width: "121px",
  },
  {
    title: "Details",
    dataIndex: "comment",
    key: "comment",
    width: "503px",
  },
];

export const addPondBreadcrumbs = [
  {
    path: "/",
    key: 1,
    breadcrumbName: "Ponds list",
  },
  {
    path: "/addPond",
    key: 2,
    breadcrumbName: "Add pond",
  },
];

export const initialBucket = {
  name: "",
  params: {
    athena: {
      enable: false,
    },
    deep_archive: true,
    colid: null,
    encryption: {
      kms_key_arn: null,
      type: EEncryption.sse_s3,
    },
    collibra: {
      enable: false,
    },
    region: "eu-central-1",
    static_website_hosting: {
      enable: false,
      authorization: false,
      cloudfront_distribution_domain_name: "",
      cloudfront_distribution_id: "",
      origin_access_control_id: "",
      website_url: "",
    },
  },
  access_levels: {},
  permissions: {
    groups: {},
    users: {},
    trusted_principals: {},
  },
  statistics: {
    bucket_size_bytes: null,
    division: "",
    number_of_objects: null,
    total_bucket_size_bytes: null,
  },
  status: {
    details: "",
    status: "OK",
  },
  management_permissions: {
    can_add_users: false,
    can_add_groups: false,
    can_add_applications: false,
    can_add_machine_users: false,
    can_add_github_repositories: false,
    can_toggle_athena: false,
    can_toggle_archive: false,
    can_toggle_static_website: false,
    can_toggle_collibra: false,
    can_add_trusted_principals: false,
    can_read_audit_logs: false,
    can_change_data_classification: false,
  },
  project: {
    name: "",
  },
  data_classification: "restricted",
  ssh_keys: [],
  kyc_link: "",
} as IPond;

export const initialCrawler = {
  enable: false,
  cloudwatch_alarm: "",
  workgroup: "",
  crawler: "",
  crawler_config: {
    Configuration: "",
    CrawlElapsedTime: 0,
    CreationTime: "",
    DatabaseName: "",
    LakeFormationConfiguration: {
      AccountId: "",
      UseLakeFormationCredentials: false,
    },
    LastCrawl: {
      LogGroup: "",
      LogStream: "",
      MessagePrefix: "",
      StartTime: "",
      Status: "",
    },
    LastUpdated: "",
    LineageConfiguration: {
      CrawlerLineageSettings: "",
    },
    Name: "",
    RecrawlPolicy: {
      RecrawlBehavior: "",
    },
    Role: "",
    State: "",
  },
  database: "",
  logs: [],
  tables: [],
  use_primary_workgroup: false,
};

export const userColumns = [
  {
    title: () => <Tooltip title="User email" tooltip={<UserEmailTooltip />} />,
    dataIndex: "email",
    render: (record: { name: string; itemKey: number }) => {
      return record.name;
    },
    key: "email",
    width: "75%",
  },
  {
    title: () => <Tooltip title="Folders" tooltip={<FolderTooltip />} />,
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              <Tag
                color={
                  item.level === "owner"
                    ? "red"
                    : item.level === "ro"
                    ? "green"
                    : "blue"
                }
              >
                {`${
                  record.value.length === 1 && record.value[0].prefix === ""
                    ? "ALL FOLDERS"
                    : "/" + item.prefix
                }`}
              </Tag>
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "15%",
  },
  {
    title: () => (
      <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
    ),
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              {item.level === "ro"
                ? "Read only"
                : item.level === "rw"
                ? "Read-write"
                : "Owner"}
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "10%",
  },
];

export const groupColumns = [
  {
    title: () => <Tooltip title="Group name" tooltip={<GroupNameTooltip />} />,
    dataIndex: "name",
    render: (record: { name: string; itemKey: number }) => {
      return record.name;
    },
    key: "name",
    width: "75%",
  },
  {
    title: () => <Tooltip title="Folders" tooltip={<FolderTooltip />} />,
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              <Tag
                color={
                  item.level === "owner"
                    ? "red"
                    : item.level === "ro"
                    ? "green"
                    : "blue"
                }
              >
                {`${
                  record.value.length === 1 && record.value[0].prefix === ""
                    ? "ALL FOLDERS"
                    : "/" + item.prefix
                }`}
              </Tag>
            </li>
          ))}
        </ul>
      );
    },
    key: "folders",
    width: "15%",
  },
  {
    title: () => (
      <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
    ),
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              {item.level === "ro"
                ? "Read only"
                : item.level === "rw"
                ? "Read-write"
                : "Owner"}
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "10%",
  },
];

export const applicationColumns = [
  {
    title: () => (
      <Tooltip
        title="Application (client) ID"
        tooltip={<AzureApplicationIdTooltip />}
      />
    ),
    dataIndex: "applicationId",
    render: (record: { name: string; itemKey: number }) => {
      return record.name;
    },
    key: "applicationId",
    width: "75%",
  },
  {
    title: () => <Tooltip title="Folders" tooltip={<FolderTooltip />} />,
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              <Tag
                color={
                  item.level === "owner"
                    ? "red"
                    : item.level === "ro"
                    ? "green"
                    : "blue"
                }
              >
                {`${
                  record.value.length === 1 && record.value[0].prefix === ""
                    ? "ALL FOLDERS"
                    : "/" + item.prefix
                }`}
              </Tag>
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "15%",
  },
  {
    title: () => (
      <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
    ),
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              {item.level === "ro"
                ? "Read only"
                : item.level === "rw"
                ? "Read-write"
                : "Owner"}
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "10%",
  },
];

export const roleColumns = [
  {
    title: "Role ARN",
    dataIndex: "name",
    render: (record: { name: string; itemKey: number }) => {
      const [role, service_role] = getRolesFromKey(record.name);

      return (
        <>
          <div>
            <strong>Role: </strong>
            {role}
          </div>
          <div>
            <strong>Service role: </strong>
            {service_role}
          </div>
        </>
      );
    },
    key: "name",
    width: "60%",
  },
  {
    title: "Created by",
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
        created_by: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>{item.created_by}</li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "15%",
  },
  {
    title: () => <Tooltip title="Folders" tooltip={<FolderTooltip />} />,
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              <Tag
                color={
                  item.level === "owner"
                    ? "red"
                    : item.level === "ro"
                    ? "green"
                    : "blue"
                }
              >
                {`${
                  record.value.length === 1 && record.value[0].prefix === ""
                    ? "ALL FOLDERS"
                    : "/" + item.prefix
                }`}
              </Tag>
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "15%",
  },
  {
    title: () => (
      <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
    ),
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              {item.level === "ro"
                ? "Read only"
                : item.level === "rw"
                ? "Read-write"
                : "Owner"}
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "10%",
  },
];

export const repoColumns = [
  {
    title: () => (
      <Tooltip title="Repository name" tooltip={<RepositoryNameTooltip />} />
    ),
    dataIndex: "name",
    render: (record: { name: string; itemKey: number }) => {
      return record.name;
    },
    key: "name",
    width: "60%",
  },
  {
    title: "Created by",
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
        created_by: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>{item.created_by}</li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "15%",
  },
  {
    title: () => <Tooltip title="Folders" tooltip={<FolderTooltip />} />,
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              <Tag
                color={
                  item.level === "owner"
                    ? "red"
                    : item.level === "ro"
                    ? "green"
                    : "blue"
                }
              >
                {`${
                  record.value.length === 1 && record.value[0].prefix === ""
                    ? "ALL FOLDERS"
                    : "/" + item.prefix
                }`}
              </Tag>
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "15%",
  },
  {
    title: () => (
      <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
    ),
    dataIndex: "type",
    render: (record: {
      value: Array<{
        level: string;
        prefix: string;
      }>;
      itemKey: number;
    }) => {
      return (
        <ul className="access-list">
          {record.value.map((item, index) => (
            <li key={index}>
              {item.level === "ro"
                ? "Read only"
                : item.level === "rw"
                ? "Read-write"
                : "Owner"}
            </li>
          ))}
        </ul>
      );
    },
    key: "type",
    width: "10%",
  },
];

export const DATA_CLASSIFICATION_LIST = [
  {
    id: 0,
    value: "public",
    label: "Public",
    isVisible: true,
  },
  {
    id: 1,
    value: "internal",
    label: "Internal",
    isVisible: true,
  },
  {
    id: 2,
    value: "restricted",
    label: "Restricted",
    isVisible: true,
  },
  {
    id: 3,
    value: "secret",
    label: "Secret",
    isVisible: isAllowSecretData,
  },
];

export const DATA_CLASSIFICATION_MAP = arrayToMapByField(
  "value",
  DATA_CLASSIFICATION_LIST,
);
