import { Card, InputNumber, message, notification, Table } from "antd";
import { useCallback, useMemo, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { IAuditLog } from "./types";
import { AxiosResponse } from "axios";
import { AUDIT_LOG_COLUMNS } from "const";
import { getAuditLog } from "http/userApi";

import Button from "common/Button";
import { useLogin } from "hooks/useLogin";

import "./style.scss";

export const AuditLog = ({ bucketName }: { bucketName: string }) => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [logs, setLogs] = useState<{ Logs: IAuditLog[] }>();
  const [loading, setLoading] = useState<boolean>(false);
  const generateRef = useRef<HTMLInputElement>(null);

  const handleShowAuditLog = useCallback(async () => {
    setLoading(true);

    if (
      generateRef.current?.value.includes(".") ||
      generateRef.current?.value.includes(",")
    ) {
      setLoading(false);
      return notification.error({
        message: "The number must be an integer",
      });
    }

    const result: {
      data: AxiosResponse<{ Logs: IAuditLog[] }> | null;
      error: null | string;
    } = await getAuditLog(
      bucketName,
      generateRef.current?.value || "50",
      msalInstance,
    );

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data?.data) {
      setLogs(result.data?.data);
    }

    if (result.error) {
      message.error({
        content: result.error,
        duration: 2,
      });
    }

    setLoading(false);
  }, [bucketName, msalInstance, showAuthorizationErrorModal]);

  const getDetails = (field: {
    [index: string]: {
      [index: string]: string;
    };
  }) => (
    <div>
      <p>Pond configuration change.</p>
      {field.added && Object.keys(field.added).length > 0 ? <b>Added:</b> : ""}
      {field.added &&
      Object.keys(field.added).length > 0 &&
      !(typeof field.added === "string" || field.added instanceof String)
        ? Object.keys(field.added).map(key => (
            <p style={{ textIndent: 3 }}>
              {key}
              {field.added[key] ? ': "' + field.added[key] + '"' : ""}
            </p>
          ))
        : ""}
      {field.added &&
      (typeof field.added === "string" || field.added instanceof String) ? (
        <p style={{ textIndent: 3 }}>{field.added}</p>
      ) : (
        ""
      )}
      {field.changed && Object.keys(field.changed).length > 0 ? (
        <b>Changed:</b>
      ) : (
        ""
      )}
      {field.changed &&
      Object.keys(field.changed).length > 0 &&
      !(typeof field.changed === "string" || field.changed instanceof String)
        ? Object.keys(field.changed).map(key => (
            <p style={{ textIndent: 3 }}>
              {key}
              {field.changed[key] ? ': "' + field.changed[key] + '"' : ""}
            </p>
          ))
        : ""}
      {field.changed &&
      (typeof field.changed === "string" || field.changed instanceof String) ? (
        <p style={{ textIndent: 3 }}>{field.changed}</p>
      ) : (
        ""
      )}
      {field.removed && Object.keys(field.removed).length > 0 ? (
        <b>Removed:</b>
      ) : (
        ""
      )}
      {field.removed &&
      Object.keys(field.removed).length > 0 &&
      !(typeof field.removed === "string" || field.removed instanceof String)
        ? Object.keys(field.removed).map(key => (
            <p style={{ textIndent: 3 }}>
              {key}
              {field.removed[key] ? ': "' + field.removed[key] + '"' : ""}
            </p>
          ))
        : ""}
      {field.removed &&
      (typeof field.removed === "string" || field.removed instanceof String) ? (
        <p style={{ textIndent: 3 }}>{field.removed}</p>
      ) : (
        ""
      )}
    </div>
  );

  const auditData = useMemo(
    () =>
      logs?.Logs.map((item, index) => ({
        key: index,
        bucket: item.Bucket,
        dateTime: item.DateTime,
        decision: item.Decision,
        event: item.Event,
        type: item.Type,
        user: item.User,
        comment: item.Changes ? getDetails(item.Changes) : "-",
      })),
    [logs?.Logs],
  );

  return (
    <>
      <Card title="Audit log" className="audit-log">
        <div>
          <div>Count of days</div>
          <div className="input-wrap">
            <InputNumber
              type="number"
              size="large"
              addonAfter="days"
              className="audit-log-input"
              ref={generateRef}
              defaultValue={1}
              min={1}
            />
            <Button
              loading={loading}
              type="primary"
              size="large"
              onClick={handleShowAuditLog}
            >
              Generate
            </Button>
          </div>
        </div>
        {logs ? (
          <Table
            dataSource={auditData}
            columns={AUDIT_LOG_COLUMNS}
            className="audit-log-table"
          />
        ) : null}
      </Card>
    </>
  );
};
