import { ChangeEvent, memo, useCallback, useEffect, useState } from "react";
import {
  Button as IconButton,
  Card,
  Input,
  message,
  Modal,
  notification,
  Select,
  Table,
} from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ERoles, IPond } from "pages/Ponds/types";
import { useMsal } from "@azure/msal-react";
import { addRole, loadedRoles } from "http/userApi";
import { generateRoles, getRolesFromKey } from "helpers";
import { roleColumns } from "const";
import { Roles as IRoles, RolesToDisplay } from "./types";
import { v4 as uuidv4 } from "uuid";

import Button from "common/Button";
import Tooltip from "common/Tooltip";

import { AccessLevelTooltip } from "../Tooltips/AccessLevelTooltip";
import { FolderTooltip } from "../Tooltips/FolderTooltip";
import { RolesTooltip } from "../Tooltips/RolesTooltip";

import "./style.scss";

const Roles = ({ bucket }: { bucket: IPond }) => {
  const msalInstance = useMsal();
  const [edit, setEdit] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [savedRoles, setSavedRoles] = useState<IRoles>();

  const [rolesCollection, setRolesCollection] = useState<RolesToDisplay[]>([]);

  useEffect(() => {
    const getRoles = async () => {
      setLoading(true);

      const result: {
        data: IRoles | null;
        error: null | string;
      } = await loadedRoles(bucket.name, msalInstance);

      if (result.data && !result.error) {
        setSavedRoles(result.data);
        setRolesCollection(generateRoles(result.data));
      }

      setLoading(false);
    };

    getRoles();
  }, [bucket.name]);

  const switchToEdit = useCallback((block: string) => setEdit(block), []);

  const editCancel = useCallback(() => {
    if (savedRoles) {
      setRolesCollection(generateRoles(savedRoles));
    }
    setEdit("");
  }, [savedRoles]);

  const handleAddSpecifyFolders = (itemKey: number) => {
    setRolesCollection(
      rolesCollection.map(rolesItem =>
        rolesItem.key === itemKey
          ? {
              ...rolesItem,
              type: {
                ...rolesItem.type,
                value: [
                  ...rolesItem.type.value,
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
              },
            }
          : rolesItem,
      ),
    );
  };

  const handleAddRole = useCallback(
    () =>
      rolesCollection.length
        ? setRolesCollection([
            ...rolesCollection,
            {
              key: rolesCollection[rolesCollection.length - 1].key + 1,
              name: {
                name: "",
                itemKey:
                  rolesCollection[rolesCollection.length - 1].name.itemKey + 1,
              },
              select: {
                value: "all",
                itemKey:
                  rolesCollection[rolesCollection.length - 1].name.itemKey + 1,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey:
                  rolesCollection[rolesCollection.length - 1].type.itemKey + 1,
              },
            },
          ])
        : setRolesCollection([
            {
              key: 0,
              name: {
                name: "",
                itemKey: 0,
              },
              select: {
                value: "all",
                itemKey: 0,
              },
              type: {
                value: [
                  {
                    level: "ro",
                    prefix: "",
                    prefixKey: uuidv4(),
                  },
                ],
                itemKey: 0,
              },
            },
          ]),
    [rolesCollection],
  );

  const savePermissions = async () => {
    setLoading(true);

    for (let i = 0; i < rolesCollection.length; i++) {
      if (rolesCollection[i].name.name === "") {
        setLoading(false);
        return notification.error({
          message:
            'The "Role name" field is required. Make sure they are filled in.',
        });
      }

      if (!/arn:aws:iam::\d{12}:role\/\w+/.test(rolesCollection[i].name.name)) {
        setLoading(false);
        return notification.error({
          message:
            "Provided Trusted Role ARN's format is not valid AWS IAM Role ARN.",
        });
      }

      if (
        rolesCollection[i].select.value === "specify" &&
        rolesCollection[i].type.value.length === 1 &&
        rolesCollection[i].type.value[0].prefix === ""
      ) {
        setLoading(false);
        return notification.error({
          message: 'The "Specify folders" field must not be empty',
        });
      }

      if (
        rolesCollection[i] &&
        rolesCollection[i + 1] &&
        rolesCollection[i].name.name === rolesCollection[i + 1].name.name
      ) {
        setLoading(false);
        return notification.error({
          message: "The role's name must be unique",
        });
      }
    }

    const roles: IRoles = {};

    rolesCollection.forEach(item => {
      const roleName = getRolesFromKey(item.name.name)[0];

      Object.assign(roles, {
        [roleName]: item.type.value.map(
          ({ prefixKey, created_by, ...rest }) => rest,
        ),
      });
    });

    const result = await addRole(roles, bucket.name, msalInstance);

    if (result.data) {
      setSavedRoles(result.data);
      setRolesCollection(generateRoles(result.data));
      message.success({
        content: "Roles was updated successfully",
        duration: 2,
      });
      setEdit("");
    }

    if (result.error) {
      if (result.error && typeof result.error === "string") {
        Modal.error({
          title: "Error",
          content: result.error,
        });
      } else if (
        (result.error as { detail: string }) &&
        !(result.error as { detail: string }).detail.includes(
          "string does not match regex",
        )
      ) {
        Modal.error({
          title: "Error",
          content: (result.error as { detail: string }).detail,
        });
      } else {
        Modal.error({
          title: "Error",
          content: `${result.error}`,
        });
      }
    }

    setLoading(false);
  };

  const handleInputValue = (
    inputValue: string,
    field: string,
    item: { name: string; itemKey: number },
  ) => {
    setRolesCollection(
      rolesCollection.map(rolesItem =>
        rolesItem.key === item.itemKey
          ? {
              ...rolesItem,
              [field]: { itemKey: item.itemKey, name: inputValue },
            }
          : rolesItem,
      ),
    );
  };

  const handleChangePrefixInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: number) => {
      setRolesCollection(
        rolesCollection.map(rolesItem =>
          rolesItem.key === key
            ? {
                ...rolesItem,
                type: {
                  ...rolesItem.type,
                  value: rolesItem.type.value.map(item =>
                    item.prefixKey === event.target.name
                      ? {
                          ...item,
                          prefix: event.target.value,
                        }
                      : item,
                  ),
                },
              }
            : rolesItem,
        ),
      );
    },
    [rolesCollection],
  );

  const handleChangeFoldersSelect = useCallback(
    (value: string, itemKey: number) => {
      setRolesCollection(
        rolesCollection.map(rolesItem =>
          rolesItem.key === itemKey
            ? {
                ...rolesItem,
                select: { value: value, itemKey },
                type: {
                  ...rolesItem.type,
                  value:
                    value === "all"
                      ? [
                          {
                            level: "ro",
                            prefix: "",
                            prefixKey: uuidv4(),
                          },
                        ]
                      : rolesItem.type.value,
                },
              }
            : rolesItem,
        ),
      );
    },
    [rolesCollection],
  );

  const handleDelete = useCallback(
    (
      roleKey: number,
      prefixKey: string,
      found: {
        level: "ro" | "owner" | "rw";
        prefix: string;
        prefixKey?: string | undefined;
      }[],
    ) => {
      if (found.length === 1) {
        setRolesCollection(prevRolesCollection =>
          prevRolesCollection.filter(rolesItem => rolesItem.key !== roleKey),
        );

        return;
      }

      setRolesCollection(prevRolesCollection =>
        prevRolesCollection.map(rolesItem =>
          rolesItem.key === roleKey
            ? {
                ...rolesItem,
                type: {
                  ...rolesItem.type,
                  value: rolesItem.type.value.filter(
                    item => item.prefixKey !== prefixKey,
                  ),
                },
              }
            : rolesItem,
        ),
      );
    },
    [rolesCollection, setRolesCollection],
  );

  const handleChangePrefixSelect = useCallback(
    (key: number, value: keyof typeof ERoles, prefixKey: string) => {
      setRolesCollection(
        rolesCollection.map(rolesItem =>
          rolesItem.key === key
            ? {
                ...rolesItem,
                type: {
                  ...rolesItem.type,
                  value: rolesItem.type.value.map(item =>
                    item.prefixKey === prefixKey
                      ? {
                          ...item,
                          level: value,
                        }
                      : item,
                  ),
                },
              }
            : rolesItem,
        ),
      );
    },
    [rolesCollection],
  );

  const editableRoleColumns = [
    {
      title: "Role ARN",
      dataIndex: "name",
      render: (record: { name: string; itemKey: number }) => {
        const [role] = getRolesFromKey(record.name);

        return (
          <Input
            title={record.name}
            defaultValue={role}
            onChange={e => handleInputValue(e.target.value, "name", record)}
          />
        );
      },
      key: "name",
      width: "40%",
    },
    {
      title: () => (
        <Tooltip title="Folders access mode" tooltip={<FolderTooltip />} />
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        return (
          <Select
            className="column-folders-select"
            value={record.value}
            onChange={(value: string) =>
              handleChangeFoldersSelect(value, record.itemKey)
            }
          >
            <Select.Option value="all">All folders</Select.Option>
            <Select.Option value="specify">Specify folders</Select.Option>
          </Select>
        );
      },
      key: "select",
      width: "10%",
    },
    {
      title: () => (
        <div className="access-titles">
          <div>Pond folders</div>
          <Tooltip title="Access level" tooltip={<AccessLevelTooltip />} />
          <div>Action</div>
        </div>
      ),
      dataIndex: "select",
      render: (record: { value: string; itemKey: number }) => {
        const foundItem = rolesCollection.find(
          item => item.key === record.itemKey,
        );

        return (
          <>
            {record.value === "all" ? (
              <div className="access-fields-wrapper">
                <Input
                  name="all"
                  onChange={e => handleChangePrefixInput(e, record.itemKey)}
                  value={foundItem?.type.value[0].prefix}
                  disabled={record.value === "all"}
                />
                <Select
                  className="item-selector"
                  onChange={value =>
                    handleChangePrefixSelect(
                      record.itemKey,
                      value,
                      foundItem?.type.value[0].prefixKey
                        ? foundItem.type.value[0].prefixKey
                        : "",
                    )
                  }
                  value={foundItem?.type.value[0].level}
                >
                  <Select.Option value="ro">Read only</Select.Option>
                  <Select.Option value="rw">Read write</Select.Option>
                </Select>
                <div style={{ maxWidth: "fit-content" }}>
                  <IconButton
                    danger
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      handleDelete(
                        record.itemKey,
                        foundItem?.type.value[0].prefixKey || "",
                        foundItem?.type.value || [],
                      )
                    }
                  />
                </div>
              </div>
            ) : (
              foundItem?.type.value.map(item => (
                <div className="access-fields-wrapper">
                  <Input
                    name={item.prefixKey}
                    onChange={e => handleChangePrefixInput(e, record.itemKey)}
                    value={item.prefix}
                    disabled={record.value === "all"}
                  />
                  <Select
                    className="item-selector"
                    onChange={value =>
                      handleChangePrefixSelect(
                        record.itemKey,
                        value,
                        item.prefixKey ? item.prefixKey : "",
                      )
                    }
                    value={item.level}
                  >
                    <Select.Option value="ro">Read only</Select.Option>
                    <Select.Option value="rw">Read write</Select.Option>
                  </Select>
                  <div style={{ maxWidth: "fit-content" }}>
                    <IconButton
                      danger
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        handleDelete(
                          record.itemKey,
                          item.prefixKey ? item.prefixKey : "",
                          foundItem.type.value,
                        )
                      }
                    />
                  </div>
                </div>
              ))
            )}
            {record.value === "all" ? null : (
              <Button
                style={{
                  width: "100%",
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size="middle"
                type="default"
                onClick={() => handleAddSpecifyFolders(record.itemKey)}
                icon={<PlusOutlined />}
              >
                Add
              </Button>
            )}
          </>
        );
      },
      key: "pondFolders",
      width: "50%",
    },
  ];

  return (
    <div className="access-pond">
      <Card
        title={<Tooltip title="AWS IAM roles" tooltip={<RolesTooltip />} />}
        className="access-pond-roles"
        extra={
          edit !== "roles" ? (
            <Button
              type="primary"
              size="large"
              onClick={() => switchToEdit("roles")}
              icon={<EditOutlined />}
              disabled={
                bucket.management_permissions.can_add_trusted_principals
                  ? false
                  : true
              }
            >
              Edit
            </Button>
          ) : (
            <div className="edit-buttons">
              <Button className="cancel-btn" onClick={editCancel} size="large">
                Cancel
              </Button>
              <Button
                htmlType="submit"
                disabled={loading ? true : false}
                type="primary"
                size="large"
                onClick={savePermissions}
              >
                Save
              </Button>
            </div>
          )
        }
      >
        {edit === "roles" ? (
          <>
            <Table
              dataSource={rolesCollection}
              columns={editableRoleColumns}
              loading={loading}
              pagination={false}
            />
            <Button
              type="dashed"
              disabled={loading}
              className="add-permissions-btn"
              icon={<PlusOutlined />}
              onClick={handleAddRole}
            >
              Add role
            </Button>
          </>
        ) : bucket.management_permissions.can_add_trusted_principals ? (
          <Table
            columns={roleColumns}
            dataSource={rolesCollection}
            pagination={false}
            loading={loading}
          />
        ) : (
          <div className="disabled-block">
            <Table
              columns={roleColumns}
              dataSource={rolesCollection}
              pagination={false}
              loading={loading}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default memo(Roles);
