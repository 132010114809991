import { Card, InputNumber, Table } from "antd";
import Button from "common/Button";

import { useCallback, useMemo, useRef } from "react";
import { useAccessLogs } from "../hooks/useAccessLogs";

type Props = {
  bucketName: string;
};

const convertValue = (value: string | number) => {
  if (typeof value === "number") {
    return value.toLocaleString("en-US");
  } else {
    return value;
  }
};

export const AccessLog = ({ bucketName }: Props) => {
  const { accessLog, loading, getAccessLogs } = useAccessLogs();

  const inputRef = useRef<HTMLInputElement>(null);

  const getAccessLog = useCallback(() => {
    const days = inputRef.current ? +inputRef.current.value : 1;
    getAccessLogs(bucketName, days);
  }, []);

  const headers = useMemo(
    () =>
      accessLog?.Headers.map((title, index) => ({
        title,
        key: index,
        dataIndex: index,
      })),
    [accessLog?.Headers],
  );

  const rows = useMemo(
    () =>
      accessLog?.Results?.map(columns =>
        columns.reduce(
          (
            acc: Record<number | string, number | string>,
            value: string,
            index: number,
          ) => {
            acc.key = index;
            acc[index] = convertValue(value);
            return acc;
          },
          {},
        ),
      ),
    [headers, accessLog?.Results],
  );

  return (
    <>
      <Card title="Access log" className="audit-log">
        <div>
          <div>Count of days</div>
          <div className="input-wrap">
            <InputNumber
              type="number"
              size="large"
              addonAfter="days"
              className="audit-log-input"
              ref={inputRef}
              defaultValue={1}
              min={1}
            />
            <Button
              loading={loading}
              type="primary"
              size="large"
              onClick={getAccessLog}
            >
              Generate
            </Button>
          </div>
        </div>
        {!!accessLog ? (
          <Table
            dataSource={rows}
            columns={headers}
            className="audit-log-table"
          />
        ) : null}
      </Card>
    </>
  );
};
