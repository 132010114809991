import { useCallback, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { message } from "antd";

import { addSSHKey, deleteSSHKey, removeMachineUser } from "http/userApi";
import { MachineUsers, SSHKey } from "../../AccessData/types";
import { useLogin } from "hooks/useLogin";

export const useMachineUsers = () => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [isDeletingSHHKey, setIsDeletingSHHKey] = useState(false);
  const [isCreatingSHHKey, setIsCreatingSHHKey] = useState(false);

  const deleteMachineUser = useCallback(
    async ({
      pondName,
      machineUserId,
      setLoading,
      setMachineUsers,
    }: {
      pondName: string;
      machineUserId: string;
      setLoading: (value: string) => void;
      setMachineUsers: (
        value: (prevState: MachineUsers[]) => MachineUsers[] | MachineUsers[],
      ) => void;
    }) => {
      setLoading("list");

      if (machineUserId) {
        const result: {
          data: string | null;
          error: null | string;
        } = await removeMachineUser(pondName, machineUserId, msalInstance);

        if (result.error === "token expired") {
          return showAuthorizationErrorModal();
        }

        if (result.data) {
          setMachineUsers((prevMachineUsers: MachineUsers[]) =>
            prevMachineUsers.filter(userItem => userItem.id !== machineUserId),
          );

          message.success({
            content: result.data,
            duration: 2,
          });
        }

        if (result.error) {
          message.error({
            content: result.error,
            duration: 2,
          });
        }
      }

      setLoading("");
    },
    [msalInstance, showAuthorizationErrorModal],
  );

  const deleteSHHKey = useCallback(
    async ({
      pondName,
      machineUserId,
      sshKeyId,
      onFetchMachineUsers,
    }: {
      pondName: string;
      machineUserId: string;
      sshKeyId: string;
      onFetchMachineUsers?: () => Promise<unknown>;
    }) => {
      setIsDeletingSHHKey(true);

      const result: {
        data: string | null;
        error: null | string;
      } = await deleteSSHKey(pondName, machineUserId, sshKeyId, msalInstance);

      if (result.error === "token expired") {
        return showAuthorizationErrorModal();
      }

      if (result.data) {
        onFetchMachineUsers && (await onFetchMachineUsers());

        message.success({
          content: result.data,
          duration: 2,
        });
      }

      if (result.error) {
        message.error({
          content: result.error,
          duration: 2,
        });
      }

      setIsDeletingSHHKey(false);
    },
    [msalInstance, setIsDeletingSHHKey, showAuthorizationErrorModal],
  );

  const submitSSHKey = useCallback(
    async ({
      pondName,
      machineUserId,
      onFetchMachineUsers,
      DTO,
      onCloseForm,
    }: {
      pondName: string;
      machineUserId: string;
      onFetchMachineUsers?: () => Promise<unknown>;
      DTO: { description: string; public_key: string };
      onCloseForm: () => void;
    }) => {
      setIsCreatingSHHKey(true);
      const result: {
        data: SSHKey | null;
        error: null | string;
      } = await addSSHKey(pondName, machineUserId, DTO, msalInstance);

      if (result.error === "token expired") {
        return showAuthorizationErrorModal();
      }

      if (result.data) {
        onFetchMachineUsers && (await onFetchMachineUsers());

        message.success({
          content: `${result.data.description} was added`,
          duration: 2,
        });

        onCloseForm();
      }

      if (result.error) {
        message.error({
          content: result.error,
          duration: 2,
        });
      }

      setIsCreatingSHHKey(false);
    },
    [msalInstance, setIsCreatingSHHKey, showAuthorizationErrorModal],
  );

  return useMemo(
    () => ({
      deleteMachineUser,
      deleteSHHKey,
      isDeletingSHHKey,
      submitSSHKey,
      isCreatingSHHKey,
    }),
    [
      deleteMachineUser,
      deleteSHHKey,
      isDeletingSHHKey,
      submitSSHKey,
      isCreatingSHHKey,
    ],
  );
};
