export enum ERoles {
  ro = "Read only",
  owner = "Owner",
  rw = "Read-write",
}

export interface Permission {
  level: keyof typeof ERoles;
  prefix: string;
  created_by: string;
}

export enum EEncryption {
  sse_s3 = "SSE_S3",
  sse_kms = "SSE_KMS",
}

export enum EncryptionTypes {
  STANDARD = "Standard",
  ADVANCED = "Advanced",
}

export enum EClassification {
  internal = "Internal",
  public = "Public",
  restricted = "Restricted",
  secret = "Secret",
}

export enum ELifeCycleStatus {
  deprecated = "Deprecated",
  released = "Released",
  under_development = "UnderDevelopment",
}

export type Colid = {
  pid_uri?: string;
  definition?: string;
  label?: string;
  licensed_data?: boolean;
  personal_data?: boolean;
  information_classification?: EClassification;
  lifecycle_status?: ELifeCycleStatus;
  owner?: string;
} | null;

export interface IPondParams {
  region: string;
  colid: Colid;
  athena: {
    cloudwatch_alarm?: string;
    crawler?: string;
    database?: string;
    enable: boolean;
    use_primary_workgroup?: boolean;
    workgroup?: string;
  };
  encryption: {
    kms_key_arn: null;
    type: EEncryption | null;
  };
  collibra: {
    enable: boolean;
  };
  deep_archive: boolean;
  enable_versioning?: boolean;
  static_website_hosting: {
    enable: boolean;
    authorization: boolean;
    cloudfront_distribution_domain_name: string;
    cloudfront_distribution_id: string;
    origin_access_control_id: string;
    website_url: string;
    is_spa_website: boolean;
  };
}

export interface IPondPermissions {
  users: {
    [index: string]: {
      level: keyof typeof ERoles;
      prefix: string;
    };
  };
  groups: {
    [index: string]: {
      level: keyof typeof ERoles;
      prefix: string;
    };
  };
  trusted_principals: {
    [index: string]: {
      level: keyof typeof ERoles;
      created_by: string;
    };
  };
}

export type IPond = {
  name: string;
  description: {
    raw: string;
    rendered: string;
  };
  params: IPondParams;
  permissions: IPondPermissions;
  access_levels: {
    [key: string]: keyof typeof ERoles;
  };
  statistics: {
    bucket_size_bytes: number | null;
    division: string;
    number_of_objects: number | null;
    total_bucket_size_bytes: number | null;
  };
  status: {
    details: string;
    status: string;
  };
  management_permissions: {
    can_add_users: boolean;
    can_add_groups: boolean;
    can_add_applications: boolean;
    can_add_machine_users: boolean;
    can_add_github_repositories: boolean;
    can_toggle_athena: boolean;
    can_toggle_archive: boolean;
    can_toggle_static_website: boolean;
    can_toggle_collibra: boolean;
    can_add_trusted_principals: boolean;
    can_read_audit_logs: boolean;
    can_change_data_classification: boolean;
    can_change_description: boolean;
  };
  project: {
    beat_id?: string;
    name: string;
    link?: string;
    description?: string;
    verified?: boolean;
    confirmed?: boolean;
  };
  data_classification: string;
  ssh_keys?: [];
  kyc_link?: string;
};

export enum EModes {
  Success = "success",
  Error = "error",
}

export interface INotification {
  mode: EModes;
  title: string;
}

export enum EPondTabsMenu {
  GENERAL = "General",
  USERS = "Users",
  CONNECT = "Connect",
  KEYS = "Keys",
  LOGS = "Logs",
}
