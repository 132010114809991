import { memo, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Form, PageHeader, Alert, Modal } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { SegmentedValue } from "antd/lib/segmented";
import { AxiosResponse } from "axios";
import { useMsal } from "@azure/msal-react";

import { addPondBreadcrumbs, initialBucket } from "const";

import { TermsOfUse } from "components/AddPond/TermsOfUse";
import AddPondGeneral from "components/AddPond/General";
import AddPondAdditional from "components/AddPond/Additional";
import AddPondColid from "components/AddPond/Colid";
import DiscardModal from "components/DiscardModal";
import Button from "common/Button";

import { useLogin } from "hooks/useLogin";
import { addBucket } from "http/userApi";

import {
  EClassification,
  EEncryption,
  ELifeCycleStatus,
  INotification,
  IPond,
} from "../Ponds/types";

import "./style.scss";

const AddPond = () => {
  const navigate = useNavigate();
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [data, setData] = useState<IPond>(initialBucket);

  const accountName: string = msalInstance.accounts[0]?.username
    ? msalInstance.accounts[0].username
    : "";

  const [segment, setSegment] = useState<SegmentedValue>("New asset");

  const [alert, setAlert] = useState(false);
  const [isSaveClicked, setSaveClicked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [notification, setNotification] = useState<INotification | null>(null);

  useEffect(() => {
    if (notification && notification.mode === "error") {
      const modal = Modal[notification.mode]({
        title: notification.title,
        className: "notification-wrapper",
        mask: false,
        style: {
          top: 64,
          right: 24,
          position: "absolute",
        },
        okText: "Retry",
        okButtonProps: {
          style: {
            display: "none",
          },
        },
        cancelText: "Cancel",
        closable: true,
        maskClosable: false,
        afterClose() {
          setNotification(null);
          modal.destroy();
        },
      });
      setTimeout(() => {
        setNotification(null);
        modal.destroy();
      }, 5000);
    }
  }, [notification, setNotification, navigate]);

  const addButtons = [
    <Link to="/" key="1">
      <Button>Cancel</Button>
    </Link>,
    <Button form="policyForm" key="submit" htmlType="submit" type="primary">
      Create Pond
    </Button>,
  ];

  const itemRender = (route: Route) => {
    if (route.path === "/addPond") {
      return <div>{route.breadcrumbName}</div>;
    } else {
      return <Link to={route.path}>{route.breadcrumbName}</Link>;
    }
  };

  const handleChangeSegment = useCallback(
    (value: SegmentedValue) => setSegment(value),
    [setSegment],
  );

  const handleChangeColid = () =>
    setData({
      ...data,
      params: {
        ...data.params,
        colid: data.params.colid
          ? null
          : {
              pid_uri: "",
              definition: `Data pond S3 bucket ${data.name}`,
              label: `Data pond S3 bucket ${data.name}`,
              licensed_data: false,
              personal_data: false,
              information_classification: EClassification.restricted,
              lifecycle_status: ELifeCycleStatus.under_development,
              owner: accountName || "aleksandr.sauta.ext@bayer.com",
            },
      },
    });

  const handleChangeArchive = () =>
    setData({
      ...data,
      params: {
        ...data.params,
        deep_archive: !data.params.deep_archive,
      },
    });

  const handleChangeAthena = () =>
    setData({
      ...data,
      params: {
        ...data.params,
        athena: {
          enable: !data.params.athena.enable,
        },
      },
    });

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setData({
      ...data,
      name: event.target.value,
    });

  const handleChangeProjectName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) =>
    setData({
      ...data,
      project: {
        ...data.project,
        name: event.target.value,
      },
    });

  const handleChangeRegion = (value: string) => {
    setData({
      ...data,
      params: {
        ...data.params,
        region: value,
      },
    });
  };

  const handleChangeDataClassification = (value: string) => {
    setData({
      ...data,
      data_classification: value,
    });
  };

  const handleChangeEncryption = (event: boolean) =>
    setData({
      ...data,
      params: {
        ...data.params,
        encryption: {
          ...data.params.encryption,
          type: event ? EEncryption.sse_kms : EEncryption.sse_s3,
        },
      },
    });

  useEffect(() => {
    if (data.params.colid) {
      if (segment === "New asset") {
        setData({
          ...data,
          params: {
            ...data.params,
            colid: {
              definition: `Data pond S3 bucket ${data.name}`,
              label: `Data pond S3 bucket ${data.name}`,
              licensed_data: false,
              personal_data: false,
              information_classification: EClassification.restricted,
              lifecycle_status: ELifeCycleStatus.under_development,
              owner: accountName || "aleksandr.sauta.ext@bayer.com",
            },
          },
        });
      } else {
        setData({
          ...data,
          params: {
            ...data.params,
            colid: {
              definition: `Data pond S3 bucket ${data.name}`,
              label: `Data pond S3 bucket ${data.name}`,
              licensed_data: false,
              personal_data: false,
              information_classification: EClassification.restricted,
              lifecycle_status: ELifeCycleStatus.under_development,
              owner: accountName || "aleksandr.sauta.ext@bayer.com",
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segment]);

  const handleChangeColidField = useCallback(
    (name: string, value: string | boolean) => {
      setData({
        ...data,
        params: {
          ...data.params,
          colid: {
            ...data.params.colid,
            [name]: value,
          },
        },
      });
    },
    [setData, data],
  );

  const createPond = async () => {
    const newData = data;

    setSaveClicked(true);

    try {
      if (segment === "Existing asset" && !newData.params.colid?.pid_uri) {
        setAlert(true);
        return;
      }

      const result: {
        data: AxiosResponse<IPond> | null;
        error: null | string;
      } = await addBucket(data, msalInstance);

      if (result.error === "token expired") {
        return showAuthorizationErrorModal();
      }

      if (result?.data) {
        navigate(`/${newData.name}`);
      }

      if (result.error) {
        return Modal.error({
          title: "Error",
          content: result.error,
          okText: "Ok",
        });
      }
    } finally {
      setSaveClicked(false);
      setIsOpenModal(false);
    }
  };

  const openConfirmModal = () => setIsOpenModal(true);
  const closeConfirmModal = () => setIsOpenModal(false);

  return (
    <div className="add-policy">
      <DiscardModal
        oldPond={initialBucket}
        isSaveButtonClicked={isSaveClicked}
        changedPond={data}
      />
      <PageHeader
        title="Add pond"
        className="breadcrumbs"
        breadcrumb={{
          routes: addPondBreadcrumbs,
          itemRender,
        }}
        extra={addButtons}
      />
      <div className="add-pond-body">
        <Form
          name="basic"
          onFinish={openConfirmModal}
          autoComplete="off"
          requiredMark={false}
          layout="vertical"
          id="policyForm"
        >
          <AddPondGeneral
            region={data.params.region}
            dataClassification={data.data_classification}
            encryption={data.params.encryption.type}
            name={data.name}
            projectName={data.project.name}
            onChangeEncryption={handleChangeEncryption}
            onChangeName={handleChangeName}
            onChangeRegion={handleChangeRegion}
            onChangeProjectName={handleChangeProjectName}
            onChangeDataClassification={handleChangeDataClassification}
          />
          <AddPondAdditional
            athena={data.params.athena.enable}
            archive={data.params.deep_archive}
            colid={data.params.colid}
            onChangeArchive={handleChangeArchive}
            onChangeAthena={handleChangeAthena}
            onChangeColid={handleChangeColid}
          />
          {alert && (
            <Alert
              message="The PID URI field is empty"
              type="error"
              showIcon
              className="alert"
            />
          )}
          {data.params.colid && (
            <AddPondColid
              colid={data.params.colid}
              data={data}
              onSetData={setData}
              onChangeColidField={handleChangeColidField}
              onSetSegment={handleChangeSegment}
              segment={segment}
            />
          )}
        </Form>
      </div>
      <Modal
        open={isOpenModal}
        confirmLoading={isSaveClicked}
        width={640}
        okText="Accept and Create Pond"
        cancelText="Cancel"
        onOk={createPond}
        onCancel={closeConfirmModal}
        cancelButtonProps={{
          disabled: isSaveClicked,
        }}
        maskClosable={false}
        closable={false}
      >
        <TermsOfUse />
      </Modal>
    </div>
  );
};

export default memo(AddPond);
