import { memo } from "react";

import { ERoles, IPond } from "pages/Ponds/types";
import { findRole } from "helpers";

import AccessData from "./components/AccessData";
import AccessGroups from "./components/AccessGroups";
import AccessUsers from "./components/AccessUsers";
import AzureApplication from "./components/AzureApplication";
import Roles from "./components/Roles";
import GitHub from "./components/GitHub";

import "./style.scss";

const Users = ({
  bucket,
  onFetchBucket,
}: {
  bucket: IPond;
  onFetchBucket: () => Promise<unknown>;
}) => {
  return (
    <div className="users-tab">
      <AccessUsers bucket={bucket} onFetchBucket={onFetchBucket} />
      <AccessGroups bucket={bucket} />
      <AzureApplication bucket={bucket} onFetchBucket={onFetchBucket} />
      <Roles bucket={bucket} />
      <AccessData
        id={bucket.name}
        accessStatus={bucket.management_permissions.can_add_machine_users}
        isOwner={findRole(bucket) === ERoles.owner}
      />
      <GitHub bucket={bucket} />
    </div>
  );
};

export default memo(Users);
