import { memo, useCallback, useEffect } from "react";
import { Tabs, TabsProps } from "antd";
import { useSearchParams } from "react-router-dom";

type ITabs = {
  name: string;
  page?: JSX.Element;
  isVisible?: boolean;
};

interface IProps extends TabsProps {
  tabs: ITabs[];
  useQueries?: boolean;
  onGetTabName?: (component: string) => void;
}

const TabMenu = ({
  tabs,
  useQueries,
  onGetTabName,
  defaultActiveKey = tabs[0].name,
  ...props
}: IProps) => {
  const [params, setParams] = useSearchParams();
  const tab = params.get("tab");

  const handleChooseTab = useCallback(
    (key: string) => {
      useQueries && setParams({ tab: key });
      onGetTabName && onGetTabName(key);
    },
    [useQueries, setParams, onGetTabName],
  );

  useEffect(() => {
    const isTabExist = tab && tabs.some(({ name }) => name === tab);
    handleChooseTab(isTabExist ? tab : defaultActiveKey);
  }, []);

  return (
    <Tabs
      defaultActiveKey={tab || defaultActiveKey}
      activeKey={tab || defaultActiveKey}
      onChange={handleChooseTab}
      {...props}
    >
      {tabs.map(({ name, isVisible = true }) =>
        isVisible ? <Tabs.TabPane tab={name} key={name} /> : null,
      )}
    </Tabs>
  );
};

export default memo(TabMenu);
