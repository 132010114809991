import { memo, useCallback, useState } from "react";
import {
  CheckOutlined,
  DownOutlined,
  LinkOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Alert, Card, Collapse, Descriptions, Row, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";

import Button from "common/Button";

const Science = ({ name }: { name: string }) => {
  const [collapseKeys, setCollapseKeys] = useState<string | string[]>(["1"]);
  const [connectView, setConnectView] = useState<SegmentedValue>(
    "About Science@Scale Exploration Zone",
  );

  const changeConnectView = useCallback((value: SegmentedValue) => {
    setConnectView(value);
  }, []);

  const collapseChange = useCallback((collapseKey: string | string[]) => {
    setCollapseKeys(collapseKey);
  }, []);

  return (
    <div>
      <Collapse
        className="block-collapse"
        onChange={collapseChange}
        expandIconPosition="end"
        defaultActiveKey={["1"]}
      >
        <Collapse.Panel
          className="block-collapse-panel"
          header="About Science@Scale Exploration Zone"
          key="1"
          extra={
            collapseKeys?.length ? (
              <Button type="default" icon={<UpOutlined />} size="large">
                Collapse
              </Button>
            ) : (
              <Button type="default" icon={<DownOutlined />} size="large">
                Expand
              </Button>
            )
          }
        >
          <div className="science-connect-segmented">
            <Segmented
              value={connectView}
              onChange={changeConnectView}
              size="large"
              options={["About Science@Scale Exploration Zone", "User guides"]}
            />
          </div>
          {connectView === "About Science@Scale Exploration Zone" ? (
            <div className="science-connect-body">
              <p>
                The Exploration Zone (aka EZ) is a managed Data Science & AI
                ecosystem for Data Scientists at Bayer. Data Scientists are able
                to create and manage their own projects for experimentation and
                fast prototyping with the possibility to manage heavy workloads
                by using AWS resources in a self-service manner. You do NOT need
                an AWS account, everything works with your Bayer credentials.
              </p>
              <p>
                The cloud instances are internally configured and they provide
                access to associated AWS S3 buckets. The EZ offers server for
                RStudio, Jupyter, Deep Learning (based on Python) and Trifacta.
              </p>
              <p>
                You can choose which and how many of the tools you would like to
                add to your project, the sizing configurations of the instances
                (including GPUs) and you can add members to your project all in
                self-serviced manner.
              </p>
              <Row>
                <Descriptions
                  column={1}
                  className="python-collapse-description"
                >
                  <Descriptions.Item>
                    <div className="check">
                      <CheckOutlined />
                    </div>
                    creating and managing your own projects for experimentation
                    and fast prototyping
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions
                  column={1}
                  className="python-collapse-description second"
                >
                  <Descriptions.Item>
                    <div className="check">
                      <CheckOutlined />
                    </div>
                    NOT need to have an AWS account, everything works with your
                    Bayer credentials
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            </div>
          ) : (
            <div className="science-connect-body">
              <div className="links links-science">
                <div className="links-item">
                  <LinkOutlined />
                  <a
                    href="https://data-at-scale.docs.cloud.bayer.com/modules/datapond-api/docs/user_guide#athena"
                    target="_blank"
                  >
                    Science@Scale user guide
                  </a>
                </div>
                <div className="links-item">
                  <LinkOutlined />
                  <a
                    href="https://data-at-scale.docs.cloud.bayer.com/modules/datapond-api/docs/user_guide#programmatic-access-to-to-the-pond"
                    target="_blank"
                  >
                    Programmatic access to the pond
                  </a>
                </div>
              </div>
            </div>
          )}
        </Collapse.Panel>
      </Collapse>
      <Card title="How to get access" className="science-access">
        <div>
          <Alert
            message="NOTE:"
            description={
              <>
                <div>
                  Depending on your S@S image version, you might need to update
                  the library to the latest version.
                </div>
                <div>
                  In that case running{" "}
                  <span className="alert-bold">
                    'pip install --upgrade oauth-keys2'
                  </span>{" "}
                  in the terminal and restarting Jupyter kernel should be
                  enough.
                </div>
              </>
            }
            type="warning"
            showIcon
          />
        </div>
        <div className="snippet-mb"></div>
        <div className="snippet-mb">import oauth_keys2</div>
        <div>
          # Get IAM credentials from Access Layer - returns boto3 client. Can be
          done once.
        </div>
        <div>s3 = oauth_keys2.get_s3_client(</div>
        <div className="snippet-padding">"iam/user",</div>
        <div className="snippet-padding">
          api_url="{process.env.REACT_APP_AUTHZ_API_URL}",
        </div>
        <div className="snippet-padding">
          api_key="{process.env.REACT_APP_AUTHZ_API_KEY}",
        </div>
        )<div># Access the bucket</div>
        <div>s3.list_objects_v2(Bucket="{name}")</div>
      </Card>
    </div>
  );
};

export default memo(Science);
