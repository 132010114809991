import { EncryptionTypes } from "pages/Ponds/types";

export const EncryptionTooltip = ({ type }: { type: EncryptionTypes }) => (
  <>
    {type === EncryptionTypes.STANDARD && <>AWS-managed key (SSE-S3).</>}
    {type === EncryptionTypes.ADVANCED && (
      <>For advanced - "Customer-managed KMS key (SSE-KMS)."</>
    )}
  </>
);
