import { memo, useCallback, useEffect, useState } from "react";
import { Card, Form, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { DownOutlined, EditOutlined, UpOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import Button from "common/Button";
import Tooltip from "common/Tooltip";
import Markdown from "react-markdown";

import { IPond } from "pages/Ponds/types";
import { updateDescription } from "http/userApi";

import "./style.scss";

const DESCRIPTION =
  "The field can be used by the pond owner to add a description in a markdown format.";

type IProps = {
  bucket: IPond;
  onFetchBucket: () => Promise<unknown>;
  loading: boolean;
  onSetLoading: (value: boolean) => void;
  showError: (error: string | null) => void;
};

const Description = ({
  bucket,
  onFetchBucket,
  loading,
  onSetLoading,
  showError,
}: IProps) => {
  const [description, setDescription] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const msalInstance = useMsal();

  useEffect(() => {
    setDescription(bucket?.description.raw);
    const textarea: HTMLElement | null =
      document.getElementById("edit-description");
    if (textarea)
      textarea.setAttribute(
        "style",
        `height: ${textarea?.scrollHeight}px !important; overflow: hidden;`,
      );
  }, [bucket, isEdit]);

  const handleChangeDescription = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event.target.value);
    },
    [setDescription],
  );

  const collapseChange = () => {
    setIsCollapse(!isCollapse);
  };

  const handleEditDescriptionChange = () => {
    setIsEdit(!isEdit);
  };

  const handleSaveDescriptionChange = async () => {
    onSetLoading(true);
    if (bucket?.name) {
      const result: {
        data: IPond | null;
        error: null | string;
      } = await updateDescription(bucket?.name, description, msalInstance);

      showError(result?.error);

      if (result?.data) {
        onFetchBucket();
        message.success({
          content: "Description updated successfully",
          duration: 2,
        });
      }
    }
    setIsEdit(false);
    onSetLoading(false);
  };

  return (
    <Card
      title={
        <Tooltip
          title="Description"
          tooltip={
            <>
              <span>{DESCRIPTION}</span>
              <a href="https://commonmark.org/help/" target="_blank">
                {" Documentation"}
              </a>
            </>
          }
        />
      }
      className="pond-page-card"
      extra={
        isEdit ? (
          <div className="action-buttons">
            <Button
              className="cancel-btn"
              onClick={handleEditDescriptionChange}
              size="large"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              disabled={loading ? true : false}
              type="primary"
              size="large"
              onClick={handleSaveDescriptionChange}
            >
              Save
            </Button>
          </div>
        ) : (
          <div className="action-buttons">
            {isCollapse ? (
              <Button
                type="default"
                icon={<UpOutlined />}
                onClick={collapseChange}
                size="large"
              >
                Collapse
              </Button>
            ) : (
              <Button
                type="default"
                icon={<DownOutlined />}
                onClick={collapseChange}
                size="large"
              >
                Expand
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              onClick={handleEditDescriptionChange}
              icon={<EditOutlined />}
              disabled={
                bucket.management_permissions.can_change_description
                  ? false
                  : true
              }
            >
              Edit
            </Button>
          </div>
        )
      }
    >
      <div className="description">
        {isEdit ? (
          <Form className="form">
            <Form.Item>
              <TextArea
                id="edit-description"
                value={description}
                onChange={handleChangeDescription}
                maxLength={5000}
              />
            </Form.Item>
          </Form>
        ) : (
          <>
            <Markdown className={`content ${isCollapse ? "" : "standart"}`}>
              {description || "No description"}
            </Markdown>
          </>
        )}
      </div>
    </Card>
  );
};

export default memo(Description);
