import { memo, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Collapse, Descriptions, Row, Segmented } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { SegmentedValue } from "antd/lib/segmented";

import Button from "common/Button";
import { EPondTabsMenu, IPond } from "pages/Ponds/types";

import "./style.scss";

const PythonConnect = ({
  bucket,
  setConnectPage,
}: {
  bucket: IPond;
  setConnectPage: (value: SegmentedValue) => void;
}) => {
  const [, setParams] = useSearchParams();

  const [collapseKeys, setCollapseKeys] = useState<string | string[]>(["1"]);
  const [connectView, setConnectView] = useState<SegmentedValue>(
    "Example code for temporary credentials / static credentials",
  );

  const changeConnectView = useCallback((value: SegmentedValue) => {
    setConnectView(value);
  }, []);

  const collapseChange = useCallback((collapseKey: string | string[]) => {
    setCollapseKeys(collapseKey);
  }, []);

  const switchToScience = useCallback(() => {
    setConnectPage("Science@Scale");
  }, [setConnectPage]);

  const switchToOthers = useCallback(() => {
    setConnectPage("Other connectors");
  }, [setConnectPage]);

  const switchToKeysTab = useCallback(() => {
    setParams({ tab: EPondTabsMenu.KEYS });
  }, []);

  const switchToUsersTab = useCallback(() => {
    setParams({ tab: EPondTabsMenu.USERS });
  }, []);

  return (
    <div>
      <Collapse
        className="block-collapse"
        onChange={collapseChange}
        expandIconPosition="end"
        defaultActiveKey={["1"]}
      >
        <Collapse.Panel
          className="block-collapse-panel"
          header="About Python connector"
          key="1"
          extra={
            collapseKeys?.length ? (
              <Button type="default" icon={<UpOutlined />} size="large">
                Collapse
              </Button>
            ) : (
              <Button type="default" icon={<DownOutlined />} size="large">
                Expand
              </Button>
            )
          }
        >
          <p className="block-collapse-descr">
            There are different ways to connect to a pond data. You can use a
            specialized Bayer environment (
            <span className="blue-link" onClick={switchToScience}>
              Science@Scale
            </span>
            ), side services for data visualization (
            <span className="blue-link" onClick={switchToOthers}>
              Tableau, Power BI, any SQL client
            </span>
            ). The example below demonstrates the generic way of connecting to
            the underlying S3 bucket using native AWS API from Python code. The
            same principle can be applied to any tool that is using native S3
            connections.
          </p>
        </Collapse.Panel>
      </Collapse>
      <Card title="How to connect" className="python-connect">
        <div className="python-connect-segmented">
          <Segmented
            value={connectView}
            onChange={changeConnectView}
            size="large"
            options={[
              "Example code for temporary credentials / static credentials",
              "Trusted role",
            ]}
          />
        </div>
        {connectView ===
        "Example code for temporary credentials / static credentials" ? (
          <>
            <div className="python-connect-text">
              <ul>
                <li>
                  If you want access to pond data for a few hours (e.g. for
                  testing), get the keys{" "}
                  <span className="blue-link" onClick={switchToKeysTab}>
                    here
                  </span>{" "}
                  and use the snippet below.
                </li>
                <li>
                  If you want to access pond data for a long period of time (a
                  month or more), create a static credentials{" "}
                  <span className="blue-link" onClick={switchToUsersTab}>
                    here
                  </span>{" "}
                  and use the same snippet below.
                </li>
              </ul>
            </div>
            <div className="python-connect-body">
              <div className="snippet-mb">import boto3</div>
              <div>s3_client = boto3.client(</div>
              <div className="snippet-padding">"s3",</div>
              <div className="snippet-padding">
                aws_access_key_id="PUT YOUR AWS_ACCESS_KEY_ID",
              </div>
              <div className="snippet-padding">
                aws_secret_access_key="PUT YOUR AWS_SECRET_ACCESS_KEY",
              </div>
              )<div className="snippet-mb"></div>
              <div>s3_client.list_objects_v2(Bucket="{bucket.name}")</div>
            </div>
          </>
        ) : (
          <>
            <div className="python-connect-body">
              <p className="python-connect-descr">
                When an AWS service needs access to a data pond, you can create
                a role that will grant that access. To set up a role, follow the
                instruction below:
              </p>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">1</div>
                    <span className="bold">Create a role in Amazon.</span>{" "}
                    Create a IAM role in your AWS account with needed permission
                    -{" "}
                    <a
                      href="https://docs.aws.amazon.com/IAM/latest/UserGuide/tutorial_cross-account-with-roles.html"
                      target="_blank"
                    >
                      instruction
                    </a>
                    .
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">2</div>
                    <span className="bold">
                      Create a role in Data Pond.
                    </span>{" "}
                    Add trusted IAM role ARN with to Data Pond and assign it an
                    access level.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <p className="python-connect-descr">
                This way data pond will add this role to trusted, and given IAM
                role can directly access the pond without additional keys.
              </p>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default memo(PythonConnect);
