import { Card, Image } from "antd";
import { CodeViewer } from "common/CodeViewer";

import { Collapse } from "common/Collapse";
import { SFTP_URL } from "const";
import { EPondTabsMenu } from "pages/Ponds/types";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const PREVIEW_SIZE = 120;

export const HowToGetAccessCard = () => {
  const [, setParams] = useSearchParams();

  const switchToUsersTab = useCallback(
    () =>
      setParams({ tab: EPondTabsMenu.USERS, section: "static-credentials" }),
    [],
  );

  const howToGetAccessCollapseItems = useMemo(
    () => [
      {
        title: "Step 1: Create static user",
        body: (
          <div className="sftp-connect__container">
            <ol>
              <li>Open your pond details.</li>
              <li>
                Go to the "Users"{" "}
                <a style={{ color: "#1890ff" }} onClick={switchToUsersTab}>
                  page
                </a>
                .
              </li>
              <li>Find "Static credentials" section.</li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_1.png"}
                alt="Static credentials section"
              />
              <li>Click on "Add credentials" button.</li>
              <li>Provide a Description and set user permissions.</li>
              <li>Click "Generate" to create the static user.</li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_2.png"}
                alt="Add credentials modal"
              />
              <li>
                In the "Secret access key" modal window that appears, copy keys
                and click "I copied credentials".
              </li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_3.png"}
                alt="Secret access key modal"
              />
            </ol>
          </div>
        ),
      },
      {
        title: "Step 2: Generate SSH Key Pair (using OpenSSH)",
        body: (
          <div className="sftp-connect__container">
            <ol>
              <li>Open your terminal.</li>
              <li>
                Use the following command to generate an SSH key pair. Replace{" "}
                <strong>OUTPUT_KEY_FILE</strong> with your desired key filename.
                <CodeViewer
                  code={["ssh-keygen -t ed25519 -f OUTPUT_KEY_FILE"]}
                />
              </li>
              <li>
                When prompted, you can set a passphrase for added security.
              </li>
              <li>Save the generated key.</li>
            </ol>
          </div>
        ),
      },
      {
        title: "Step 2: Generate an SSH Key Pair (using PuTTYgen on Windows)",
        body: (
          <div className="sftp-connect__container">
            <ol>
              <li>Download and install PuTTYgen if you haven't already.</li>
              <li>Open PuTTYgen.</li>
              <li>Click "Generate" to create a new SSH key pair.</li>
              <li>Make sure you choose the ED25519 key type (recommended).</li>
              <li>Save the generated key.</li>
            </ol>
            <p>
              More information you can find{" "}
              <a
                href="https://www.ssh.com/academy/ssh/putty/windows/puttygen"
                target="_blank"
              >
                here
              </a>{" "}
              or{" "}
              <a
                href="https://the.earth.li/~sgtatham/putty/0.79/htmldoc/Chapter8.html#pubkey-puttygen"
                target="_blank"
              >
                here.
              </a>
            </p>
          </div>
        ),
      },
      {
        title: "Step 3: Add Key Pair to the pond",
        body: (
          <div className="sftp-connect__container">
            <ol>
              <li>Open your pond details.</li>
              <li>
                Go to the "Users"{" "}
                <a style={{ color: "#1890ff" }} onClick={switchToUsersTab}>
                  page
                </a>
                .
              </li>
              <li>Find the static user you created earlier.</li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_4.png"}
                alt="Static user form"
              />
              <li>
                Click "Add SSH key" to upload the public key generated in Step
                2.
              </li>
              <li>
                Provide a Description and Public Key in OpenSSH format.
                <div>
                  <strong style={{ color: "#DC143C" }}>!!!WARNING!!!</strong>
                  <div>
                    Paste your PUBLIC key here, i.e., the file ending with
                    ".pub" for ssh-keygen or contents of public key text box in
                    PuTTYgen!
                  </div>
                </div>
              </li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_5.png"}
                alt="Add SSH key modal"
              />
              <li>Click "Add key" to upload the public key.</li>
            </ol>
            The SSH keys section will appear in the created static user:
            <ol>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_6.png"}
                alt="SSH key form"
              />
            </ol>
          </div>
        ),
      },
      {
        title: "Step 4: Connect Using OpenSSH (Linux/Mac)",
        body: (
          <div className="sftp-connect__container">
            <div>
              Use the following command to connect to Datapond using OpenSSH.
              Replace <strong>ACCESS_KEY_ID</strong> and{" "}
              <strong>OUTPUT_KEY_FILE</strong> (your Datapond Access Key ID) and
              adjust the filename if you used a different one:
            </div>
            <CodeViewer
              code={[`sftp -i OUTPUT_KEY_FILE ACCESS_KEY_ID@${SFTP_URL}`]}
            />
          </div>
        ),
      },
      {
        title: "Step 4: Connect Using WinSCP (Windows)",
        body: (
          <div className="sftp-connect__container">
            <ol>
              <li>Install and run WinSCP.</li>
              <li>Click by New Tab.</li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_scp1.png"}
                alt="WinSCP app"
              />
              <li>Select SFTP file protocol.</li>
              <li>
                Provide a Host name and port. (
                <i>You can get it from the SFTP properties section</i>).
              </li>
              <li>
                Enter your Access Key ID to User name field. (
                <i>You can get it from the machine user section</i>).
              </li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_scp2.png"}
                alt="WinSCP app"
              />
              <li>Click "Advanced" button.</li>
              <li>Choose "Authentication" section in menu.</li>
              <li>
                Choose the .ppk file with your private SSH key that you
                generated in step 2.
              </li>
              <Image
                height={PREVIEW_SIZE}
                src={"./sftp/step_scp3.png"}
                alt="WinSCP advanced"
              />
              <li>Press "OK".</li>
              <li>Press "Login" to connect.</li>
            </ol>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Card title="How to get access" className="sftp-connect__card">
      <Collapse
        classNameComponent="small no-margin"
        items={howToGetAccessCollapseItems}
      />
    </Card>
  );
};
