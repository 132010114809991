export const AccessLevelTooltip = () => (
  <>
    <div>
      Access level to the pond. Can be set for the whole bucket or on specific
      folders. Note that users can list (but not look into) all folders in the
      path from bucket root to their folder.
    </div>
    <div style={{ color: "#ffc107" }}>
      !WARNING! If the user is added as "owner" to the root of the bucket, they
      can manage the pond, including adding other users!
    </div>
  </>
);
