import { memo, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Space, Table, Empty, Dropdown, Menu, message } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

import { PONDS_TABLE_COLUMNS } from "const";
import { IPond } from "./types";
import { convertBytes, findRole, getAdditionalDetails } from "helpers";
import useFetch from "hooks/useFetch";
import { useLogin } from "hooks/useLogin";
import { bucketUrl, explorerUrl, hostnameUrl } from "http/index";
import ListPageHeader from "components/ListPageHeader";
import { useRegions } from "hooks/requests/useRegions";

import "./style.scss";

const Ponds: React.FC = () => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const { data, isLoading, token } = useFetch<Array<IPond>>(
    `${bucketUrl}v2/s3buckets`,
    msalInstance,
  );

  const { regions, isLoading: isLoadingRegions } = useRegions();

  useEffect(() => {
    if (token === "token expired") {
      showAuthorizationErrorModal();
    }
  }, [showAuthorizationErrorModal, token]);

  const [ponds, setPonds] = useState<Array<IPond>>([]);

  const [search, setSearch] = useState("");

  const [searchedPonds, setSearchedPonds] = useState<Array<IPond>>([]);

  useEffect(
    () =>
      setSearchedPonds(
        ponds.filter(item =>
          item.name.toLowerCase().includes(search.toLowerCase()),
        ),
      ),
    [search, ponds],
  );

  useEffect(() => {
    if (data) {
      setPonds(data);
    }
  }, [data]);

  const copyBucketName = (name: string) => {
    navigator.clipboard.writeText(name);
    message.success({
      content: "Bucket name is copied",
      duration: 2,
    });
  };

  const getPondstableValues = useMemo(
    () =>
      searchedPonds.map(item => ({
        key: item.name,
        name: item.name,
        region: item.params.region,
        role: findRole(item),
        storageSize: item.statistics.bucket_size_bytes,
        storage: item.statistics.bucket_size_bytes
          ? convertBytes(item.statistics.bucket_size_bytes)
          : item.statistics.bucket_size_bytes &&
            item.statistics.bucket_size_bytes <= 0
          ? "-"
          : "-",
        files: item.statistics.number_of_objects
          ? item.statistics.number_of_objects
          : 0,
        features: getAdditionalDetails(item.params),
        actions: (
          <Space size="middle">
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      key: "1",
                      label: <Link to={`/${item.name}`}>Open details</Link>,
                    },
                    {
                      key: "2",
                      label: (
                        <div onClick={() => copyBucketName(item.name)}>
                          Copy name
                        </div>
                      ),
                    },
                    {
                      key: "3",
                      label: (
                        <a
                          target="_blank"
                          href={`${hostnameUrl}${explorerUrl}?bucket_name=${item.name}&bucket_region=${item.params.region}`}
                        >
                          Open in Explorer
                        </a>
                      ),
                    },
                  ]}
                />
              }
            >
              <a>
                <EllipsisOutlined />
              </a>
            </Dropdown>
          </Space>
        ),
      })),
    [searchedPonds, findRole],
  );

  return (
    <div className="ponds-list">
      <ListPageHeader setSearchItems={setSearch} />
      <div className="ponds-table">
        <Table
          dataSource={getPondstableValues}
          columns={PONDS_TABLE_COLUMNS({ regions })}
          locale={{
            emptyText: <Empty className="empty-text" description="No ponds" />,
          }}
          loading={isLoading || isLoadingRegions}
          pagination={{
            pageSize: 10,
            pageSizeOptions: [5, 10],
          }}
        />
      </div>
    </div>
  );
};

export default memo(Ponds);
