import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Card, Descriptions, message, notification, Switch } from "antd";
import { Params, useParams } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";

import { IPond } from "pages/Ponds/types";
import {
  activeStaticSite,
  changeStaticAuth,
  disableStaticSite,
  changeStaticSPAMode,
} from "http/userApi";
import Tooltip from "common/Tooltip";
import Spinner from "common/Spinner";
import useFetch from "hooks/useFetch";
import { useLogin } from "hooks/useLogin";
import { bucketUrl } from "http/index";
import { StaticSiteConfig } from "./types";

import "./style.scss";

type IProps = {
  bucket: IPond;
  onFetchBucket: () => Promise<unknown>;
  loading: boolean;
  onSetLoading: (value: boolean) => void;
};

const StaticSite = ({
  bucket,
  onFetchBucket,
  loading,
  onSetLoading,
}: IProps) => {
  const { id }: Params<string> = useParams();
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [enable, setEnable] = useState(false);

  const { data, error, isLoading, fetchData } = useFetch<StaticSiteConfig>(
    `${bucketUrl}v2/s3buckets/${id}/website`,
    msalInstance,
  );

  const { CallerReference: siteUri = "" } =
    data?.static_website_hosting_config?.DistributionConfig || {};

  useEffect(() => {
    if (error?.response?.status === 403) {
      const allowError = error.response.data as {
        detail: string;
      };

      return notification.error({
        message: "Static website issue",
        description: allowError.detail,
        duration: 5,
      });
    }

    if (error?.response?.status === 404 || error?.response?.status === 500) {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [error]);

  const labelStaticStyle = useMemo(
    () => ({
      width: 170,
      color: "rgba(0, 0, 0, 0.45)",
    }),
    [],
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://${siteUri}`);

    message.success({
      content: "Website URL string is copied",
      duration: 2,
    });
  };

  const switchStaticProps = async (checked: boolean) => {
    onSetLoading(true);

    const switchAction =
      checked === true ? activeStaticSite : disableStaticSite;

    const result: {
      data: string | StaticSiteConfig | null;
      error: null | string;
    } = await switchAction(bucket.name, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (typeof result.data !== "string" && result.data !== null) {
      await onFetchBucket();
      await fetchData();
    }

    if (result.data === "Static website was disabled") {
      await onFetchBucket();
      await fetchData();

      message.success({
        content: "Static site props has been changed",
        duration: 2,
      });
    }

    if (result.error) {
      setEnable(enable);

      message.error({
        content: "Something went wrong. Try reloading",
        duration: 2,
      });
    }

    onSetLoading(false);
  };

  const switchSPAMode = useCallback(
    async (checked: boolean) => {
      onSetLoading(true);

      const result: {
        data: StaticSiteConfig | null;
        error: null | string;
      } = await changeStaticSPAMode(bucket.name, checked, msalInstance);

      if (result.data) {
        await onFetchBucket();
        await fetchData();

        message.success({
          content: "Static site props has been changed",
          duration: 2,
        });
      }

      if (result.error) {
        await onFetchBucket();
        await fetchData();

        message.error({
          content: "Something went wrong. Try reloading",
          duration: 2,
        });
      }

      onSetLoading(false);
    },
    [bucket.name, fetchData, onFetchBucket],
  );

  const switchRestrictAuth = useCallback(
    async (checked: boolean) => {
      onSetLoading(true);

      const result: {
        data: StaticSiteConfig | null;
        error: null | string;
      } = await changeStaticAuth(bucket.name, checked, msalInstance);

      if (result.data) {
        await onFetchBucket();
        await fetchData();

        message.success({
          content: "Static site props has been changed",
          duration: 2,
        });
      }

      if (result.error) {
        await onFetchBucket();
        await fetchData();

        message.error({
          content: "Something went wrong. Try reloading",
          duration: 2,
        });
      }

      onSetLoading(false);
    },
    [bucket.name, fetchData, onFetchBucket],
  );

  return (
    <Card
      title="Static site hosting"
      className="static-card"
      bodyStyle={{
        padding: 0,
      }}
    >
      <div className="static-card-descr">
        When this option is enabled, the HTML files placed in the folder with
        the name 'public' in your pond are exposed as a static web site. Bayer
        users can access the site using the URL that will be displayed in the
        pond's details. The website is reachable to all users, authorized with
        Bayer Azure AD (including guest users). NOTE: It takes up to 10 minutes
        to provide website hosting for the first time.
      </div>
      <div className="static-card-body">
        {isLoading ? (
          <Spinner />
        ) : (
          bucket && (
            <Descriptions column={1}>
              <Descriptions.Item
                label="Static site hosting"
                labelStyle={labelStaticStyle}
              >
                <Switch
                  onChange={switchStaticProps}
                  loading={loading}
                  checked={bucket.params.static_website_hosting.enable}
                  disabled={
                    !bucket.management_permissions.can_toggle_static_website
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item
                className="descr-tooltip"
                label={
                  <>
                    <span>Restrict access:</span>
                    <Tooltip tooltip="When this option is enabled, only the users added to the pond with any access level can access the static site. Microsoft Azure token is used for authentication." />
                  </>
                }
                labelStyle={labelStaticStyle}
              >
                <Switch
                  disabled={
                    !bucket.params.static_website_hosting.enable ||
                    !bucket.management_permissions.can_toggle_static_website
                  }
                  onChange={switchRestrictAuth}
                  loading={loading}
                  checked={bucket.params.static_website_hosting.authorization}
                />
              </Descriptions.Item>
              <Descriptions.Item
                className="descr-tooltip"
                label={
                  <>
                    <span>SPA mode:</span>
                    <Tooltip tooltip="Return index page with HTTP status code 200 (OK) for non-existent paths to allow Single Page Application hosting" />
                  </>
                }
                labelStyle={labelStaticStyle}
              >
                <Switch
                  onChange={switchSPAMode}
                  loading={loading}
                  checked={bucket.params.static_website_hosting.is_spa_website}
                  disabled={
                    !bucket.params.static_website_hosting.enable ||
                    !bucket.management_permissions.can_toggle_static_website
                  }
                />
              </Descriptions.Item>
              {siteUri && bucket.params.static_website_hosting.enable ? (
                <Descriptions.Item
                  label="Site URI"
                  labelStyle={labelStaticStyle}
                >
                  <div>
                    <a target="_blank" href={`https://${siteUri}`}>
                      {siteUri}
                    </a>
                    <CopyOutlined className="copy-icon" onClick={handleCopy} />
                  </div>
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          )
        )}
      </div>
    </Card>
  );
};

export default memo(StaticSite);
