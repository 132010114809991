import { Link } from "react-router-dom";

export const GitHubRepositoriesTooltip = () => (
  <>
    The owner can add github repository name (including "bayer-int" organization
    part) to be able to fetch AWS keys for this bucket in GitHub Actions
    pipeline using GitHub internal{" "}
    <a
      href="https://docs.github.com/en/actions/deployment/security-hardening-your-deployments/about-security-hardening-with-openid-connect"
      target="_blank"
    >
      OIDC provider
    </a>
    . See{" "}
    <Link
      to={{
        search: "?tab=Connect",
      }}
    >
      Connectors
    </Link>{" "}
    page.
  </>
);
