export const VersioningTooltip = () => (
  <>
    Versioning is enabled for all objects in the pond. If you accidentally
    delete or overwrite a file, you can always restore the original version. See{" "}
    <a
      href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/Versioning.html"
      target="_blank"
    >
      AWS S3 versioning guide
    </a>{" "}
    for more details.
  </>
);
