import { SFTPPropertiesCard } from "./SFTPProperties";
import { AboutSFTPCollapse } from "./AboutSFTP";
import { HowToGetAccessCard } from "./HowToGetAccess";

export const SFTP = () => {
  return (
    <>
      <SFTPPropertiesCard />
      <AboutSFTPCollapse />
      <HowToGetAccessCard />
    </>
  );
};
