import React, { useMemo } from "react";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import { Button as AntButton, ButtonProps } from "antd";

import "./style.scss";

type ButtonType =
  | "primary"
  | "link"
  | "default"
  | "secondary"
  | "ghost"
  | "dashed"
  | "text"
  | undefined;

interface Props extends Omit<ButtonProps, "type"> {
  size?: SizeType;
  type?: ButtonType;
  icon?: JSX.Element;
  children: string | undefined;
  values?: string[];
  onClick?(): void;
  className?: string;
}

const Button: React.FC<Props> = ({
  size = "middle",
  type = "default",
  icon,
  children,
  onClick,
  className,
  ...rest
}) => {
  const handleTypeButton = (item: ButtonType) => {
    if (item === "secondary") {
      return "primary";
    } else {
      return item;
    }
  };

  const typeButton = useMemo(() => handleTypeButton(type), [type]);

  return (
    <AntButton
      type={typeButton}
      onClick={onClick}
      className={`btn ${
        type === "secondary"
          ? `${className || ""} secondary-btn`
          : className || ""
      }`}
      icon={icon}
      size={size}
      {...rest}
    >
      {children}
    </AntButton>
  );
};

export default React.memo(Button);
