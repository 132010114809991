import { memo, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card, Collapse, Descriptions, Row, Segmented } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { SegmentedValue } from "antd/lib/segmented";

import Button from "common/Button";
import { EPondTabsMenu } from "pages/Ponds/types";

import ab from "assets/img/ab.png";
import power from "assets/img/power.png";

import "./style.scss";

const OtherConnectors = () => {
  const [, setParams] = useSearchParams();

  const [collapseKeys, setCollapseKeys] = useState<string | string[]>(["1"]);
  const [connectView, setConnectView] =
    useState<SegmentedValue>("Temporary access");

  const changeConnectView = useCallback((value: SegmentedValue) => {
    setConnectView(value);
  }, []);

  const collapseChange = useCallback((collapseKey: string | string[]) => {
    setCollapseKeys(collapseKey);
  }, []);

  const switchToCredsTab = useCallback(
    () => setParams({ tab: EPondTabsMenu.KEYS }),
    [],
  );

  const switchToUsersTab = useCallback(
    () =>
      setParams({ tab: EPondTabsMenu.USERS, section: "static-credentials" }),
    [],
  );

  return (
    <>
      <Collapse
        className="block-collapse"
        onChange={collapseChange}
        expandIconPosition="end"
        defaultActiveKey={["1"]}
      >
        <Collapse.Panel
          className="block-collapse-panel"
          header="Other connectors"
          key="1"
          extra={
            collapseKeys?.length ? (
              <Button type="default" icon={<UpOutlined />} size="large">
                Collapse
              </Button>
            ) : (
              <Button type="default" icon={<DownOutlined />} size="large">
                Expand
              </Button>
            )
          }
        >
          <div className="other-connectors-wrapper">
            <p>
              Data Pond supports a large number of other connectors, such as:
            </p>
            <div className="other-connectors">
              <div className="other-connectors-item">
                <img src={ab} alt="other connectors item" />
              </div>
              <div className="other-connectors-item">
                <img src={power} alt="other connectors item" />
              </div>
              <div className="other-connectors-item">any SQL client</div>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
      <Card
        title="How to connect"
        className="other-connect"
        bodyStyle={{
          padding: 0,
        }}
      >
        <div className="other-connect-segmented">
          <Segmented
            value={connectView}
            onChange={changeConnectView}
            size="large"
            options={["Temporary access", "Static credentials"]}
          />
          {connectView === "Temporary access" ? (
            <Button type="primary" size="large" onClick={switchToCredsTab}>
              Get quick access
            </Button>
          ) : (
            <Button type="primary" size="large" onClick={switchToUsersTab}>
              Create static credentials
            </Button>
          )}
        </div>
        {connectView === "Temporary access" ? (
          <>
            <div className="other-connect-body">
              <p className="other-connect-descr">
                These keys are issued for 1-8 hours. If it is enough, follow the
                instruction below:
              </p>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">1</div>
                    <span className="bold">Open access settings.</span> Click on
                    the button above to get quick access.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">2</div>
                    <span className="bold">Create a short-term key.</span> Click
                    on the button “Get credentials” in the section “Temporary
                    access”.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">3</div>
                    <span className="bold">Copy credentials.</span> Click on the
                    button “Copy access key” to copy credentials.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">4</div>
                    <span className="bold">
                      Put credentials to application.
                    </span>{" "}
                    Paste your credentials to your applications.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            </div>
          </>
        ) : (
          <>
            <div className="other-connect-body">
              <p className="other-connect-descr">
                These keys are issued for 1/3/6 months. If you plan to use the
                keys regularly, we recommend using the machine-user:
              </p>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">1</div>
                    <span className="bold">Open access settings.</span> Click on
                    the button above to create static credentials.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">2</div>
                    <span className="bold">
                      Create a static credentials.
                    </span>{" "}
                    Click on the button “Add user” and fill the form to create a
                    static credentials.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">3</div>
                    <span className="bold">Copy credentials.</span> Once static
                    credentials are created, the pop-up appears with Access Key
                    ID and Secret Access Key. Click on the button “Copy”.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="number">4</div>
                    <span className="bold">
                      Put credentials to application.
                    </span>{" "}
                    Paste your credentials to your applications.
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default memo(OtherConnectors);
