import React, { useCallback, useMemo, useState } from "react";
import { Dropdown, Space, Menu, Modal } from "antd";
import { QuestionOutlined } from "@ant-design/icons";

import {
  FEEDBACK_URL,
  IMPRINT_URL,
  MAIL_TO_URL,
  TEAM_CHANNEL_URL,
  USER_GUIDE_URL,
} from "const";
import { TermsOfUse } from "components/AddPond/TermsOfUse";
import { PrivacyPolicyLink } from "common/PrivacyPolicyLink";

import "./style.scss";

const { datapond_api, datapond_frontend, authz_microservice, s3_explorer } =
  JSON.parse(process.env.REACT_APP_COMPONENT_VERSIONS || "{}");

enum MODAL {
  HIDE = "HIDE",
  TERMS_OF_USE = "TERMS_OF_USE",
  ABOUT = "ABOUT",
}

const SupportMenu: React.FC = () => {
  const [openModal, setOpenModal] = useState(MODAL.HIDE);

  const showModal = useCallback(
    (value: MODAL) => () => {
      setOpenModal(value);
    },
    [],
  );

  const handleCancel = useCallback(() => {
    setOpenModal(MODAL.HIDE);
  }, []);

  const spanStyle = useMemo(() => ({ display: "block" }), []);

  const supportItems = useMemo(
    () => (
      <Menu
        items={[
          {
            label: (
              <a href={USER_GUIDE_URL} target="_blank">
                User Guide
              </a>
            ),
            className: "header-support-item",
            key: "0",
          },
          {
            label: (
              <a href={TEAM_CHANNEL_URL} target="_blank">
                Contact via Teams
              </a>
            ),
            className: "header-support-item",
            key: "1",
          },
          {
            label: <a href={MAIL_TO_URL}>Contact via Email</a>,
            className: "header-support-item",
            key: "2",
          },
          {
            label: (
              <a href={FEEDBACK_URL} target="_blank">
                Provide Feedback
              </a>
            ),
            className: "header-support-item",
            key: "3",
          },
          {
            label: (
              <div onClick={showModal(MODAL.TERMS_OF_USE)}>Terms of Use</div>
            ),
            className: "header-support-item",
            key: "4",
          },
          {
            label: <PrivacyPolicyLink label="Privacy Statement" />,
            className: "header-support-item",
            key: "5",
          },
          {
            label: (
              <a href={IMPRINT_URL} target="_blank">
                Imprint
              </a>
            ),
            className: "header-support-item",
            key: "6",
          },
          {
            label: <div onClick={showModal(MODAL.ABOUT)}>About</div>,
            className: "header-support-item",
            key: "7",
          },
        ]}
      />
    ),
    [showModal],
  );

  return (
    <>
      <Dropdown
        overlay={supportItems}
        className="header-support-dropdown"
        trigger={["click"]}
      >
        <div>
          <Space className="header-support-button">
            <QuestionOutlined />
          </Space>
        </div>
      </Dropdown>
      <Modal
        title="About"
        open={openModal === MODAL.ABOUT}
        onCancel={handleCancel}
        onOk={handleCancel}
        className="support-menu-modal"
        cancelButtonProps={{
          className: "cancel-button",
        }}
      >
        <span style={spanStyle}>Data Pond API : {datapond_api}</span>
        <span style={spanStyle}>Data Pond Frond-End : {datapond_frontend}</span>
        <span style={spanStyle}>
          Access Layer Microservice : {authz_microservice}
        </span>
        <span style={spanStyle}>S3 Explorer : {s3_explorer}</span>
      </Modal>
      <Modal
        open={openModal === MODAL.TERMS_OF_USE}
        onCancel={handleCancel}
        onOk={handleCancel}
        width={640}
        className="support-menu-modal"
        cancelButtonProps={{
          className: "cancel-button",
        }}
        closable={false}
      >
        <TermsOfUse />
      </Modal>
    </>
  );
};

export default SupportMenu;
