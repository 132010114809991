import React, { memo } from "react";
import { Result } from "antd";

import "./style.scss";

const NotFound: React.FC = () => {
  return (
    <Result
      status="404"
      className="notFound-page"
      title="404 - Page not found"
      subTitle="Try to find the page manually using global menu or search."
    />
  );
};

export default memo(NotFound);
