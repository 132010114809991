import { memo, useCallback, useEffect } from "react";
import { Card, Descriptions, message, Modal, Row, Select, Switch } from "antd";
import { useMsal } from "@azure/msal-react";
import { LinkOutlined } from "@ant-design/icons";

import { EEncryption, EncryptionTypes, IPond } from "pages/Ponds/types";
import {
  disableAthena,
  enableAthena,
  switchCollibra,
  enableArchive,
  disableArchive,
  noopSwitch,
  changeBucket,
} from "http/userApi";
import {
  DATA_CLASSIFICATION_LIST,
  DATA_CLASSIFICATION_MAP,
  COLID_URL,
} from "const";

import Tooltip from "common/Tooltip";
import { useLogin } from "hooks/useLogin";

import { AthenaTooltip } from "./components/AthenaTooltip";
import { DataClassificationTooltip } from "./components/DataClassificationTooltip";
import { EncryptionTooltip } from "./components/EncryptionTooltip";
import { ArchiveTooltip } from "./components/ArchiveTooltip";
import { ColidTooltip } from "./components/ColidTooltip";
import { VersioningTooltip } from "./components/VersioningTooltip";

import "./style.scss";

const ATHENA = "Athena";
const ARCHIVE = "Archive";

const { STANDARD, ADVANCED } = EncryptionTypes;

const labelStyle = {
  width: 180,
  color: "#8c8c8c",
  alignSelf: "center",
};

const selectStyle = {
  width: 134,
};

const General = ({
  bucket,
  onFetchBucket,
  loading,
  onSetLoading,
}: {
  bucket: IPond;
  onFetchBucket: () => Promise<unknown>;
  loading: boolean;
  onSetLoading: (value: boolean) => void;
}) => {
  const msalInstance = useMsal();
  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const encryptionType =
    bucket.params.encryption.type === EEncryption.sse_s3 ? STANDARD : ADVANCED;

  useEffect(() => {
    if (
      !bucket.data_classification &&
      bucket.management_permissions.can_change_data_classification
    ) {
      Modal.warning({
        title: "Warning",
        content:
          "You have an unspecified 'Data classification' field. Please fill it out.",
        okText: "Ok",
      });
    }
  }, [bucket.data_classification]);

  const currentDataClassification = DATA_CLASSIFICATION_MAP.get(
    bucket.data_classification,
  );

  const disabledSwitch = useCallback(() => {
    if (bucket.params.athena.enable && !bucket.params.collibra.enable) {
      return false;
    } else if (bucket.params.athena.enable && bucket.params.collibra.enable) {
      return true;
    } else if (!bucket.params.athena.enable) {
      return true;
    }
  }, [bucket.params.athena.enable, bucket.params.collibra.enable]);

  const handleSwitch = (eventName: string) => async (checked: boolean) => {
    onSetLoading(true);

    let action = noopSwitch;

    switch (eventName) {
      case ATHENA:
        action = checked ? enableAthena : disableAthena;
        break;
      case ARCHIVE:
        action = checked ? enableArchive : disableArchive;
        break;

      default:
        action = noopSwitch;
        break;
    }

    const result: {
      data: string | null;
      error: null | string;
    } = await action(bucket.name, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data) {
      await onFetchBucket();
      message.success({
        content: `${eventName} has been changed`,
        duration: 2,
      });
    }

    if (result.error) {
      message.error({
        content: "Something went wrong. Try reloading",
        duration: 2,
      });
    }

    onSetLoading(false);
  };

  const activateCollibra = async () => {
    onSetLoading(true);

    const result: {
      data: string | null;
      error: null | string;
    } = await switchCollibra(bucket.name, msalInstance);

    if (result.error === "token expired") {
      return showAuthorizationErrorModal();
    }

    if (result.data) {
      await onFetchBucket();
      Modal.success({
        title: "Success",
        content: result.data,
      });
    }

    if (result.error || !result.data) {
      Modal.error({
        title: "Error",
        content: result.error,
      });
    }

    onSetLoading(false);
  };

  const handleChangeDataClassification = (value: string) => {
    if (bucket.data_classification === value) return;

    const changeDataClassification = async () => {
      onSetLoading(true);

      const result: {
        data: IPond | null;
        error: null | string;
      } = await changeBucket(
        bucket.name,
        { data_classification: value },
        msalInstance,
      );

      const { data } = result || {};

      if (result.error === "token expired") {
        return showAuthorizationErrorModal();
      }

      if (data) {
        await onFetchBucket();
        Modal.success({
          title: "Success",
          content: "Data classification was changed",
        });
      }

      if (result.error || !data) {
        Modal.error({
          title: "Error",
          content: result.error,
        });
      }

      onSetLoading(false);
    };

    return Modal.confirm({
      title: "Warning",
      content: `Are you sure you want to change the data classification to "${
        DATA_CLASSIFICATION_MAP.get(value)?.label
      }"?`,
      okText: "Yes",
      cancelText: "No",
      onOk: changeDataClassification,
    });
  };

  return (
    <>
      <Card title="General" className="pond-page-card">
        <Row>
          <Descriptions column={2}>
            <Descriptions.Item
              label="Default Encryption"
              labelStyle={labelStyle}
              className="descr-item"
            >
              <Tooltip
                title={encryptionType}
                tooltip={<EncryptionTooltip type={encryptionType} />}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={<Tooltip title="Archive" tooltip={<ArchiveTooltip />} />}
              labelStyle={labelStyle}
              className="descr-item"
            >
              <Switch
                checked={bucket.params.deep_archive}
                loading={loading}
                onChange={handleSwitch(ARCHIVE)}
                disabled={!bucket.management_permissions.can_toggle_archive}
              />
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row>
          <Descriptions column={2}>
            <Descriptions.Item
              label="Region"
              labelStyle={labelStyle}
              className="descr-item"
            >
              {bucket.params.region || "-"}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Tooltip title="COLID" tooltip={<ColidTooltip />} />}
              labelStyle={labelStyle}
              className="descr-item"
            >
              {bucket.params.colid ? (
                <span className="colid-block">
                  <span className="activated activated-item">Activated</span>
                  <a
                    href={`${COLID_URL}${bucket.params.colid.pid_uri}`}
                    target="_blank"
                  >
                    <LinkOutlined />
                    Colid link
                  </a>
                </span>
              ) : (
                <div className="activated-item">Not activated</div>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row>
          <Descriptions column={2}>
            <Descriptions.Item
              label={<Tooltip title="Athena" tooltip={<AthenaTooltip />} />}
              labelStyle={labelStyle}
              className="descr-item"
            >
              <Switch
                checked={bucket.params.athena.enable}
                loading={loading}
                onChange={handleSwitch(ATHENA)}
                disabled={!bucket.management_permissions.can_toggle_athena}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Tooltip title="Versioning" tooltip={<VersioningTooltip />} />
              }
              labelStyle={labelStyle}
              className="descr-item"
            >
              {bucket.params.enable_versioning ? (
                <div className="activated-item activated">Activated</div>
              ) : (
                <div className="activated-item">Not activated</div>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        {process.env.REACT_APP_ENABLE_COLLIBRA.toLowerCase() === "true" && (
          <Row>
            <Descriptions column={2}>
              <Descriptions.Item
                label="Collibra"
                labelStyle={labelStyle}
                className="descr-item"
              >
                <Switch
                  checked={bucket.params.collibra.enable}
                  disabled={disabledSwitch()}
                  loading={loading}
                  onChange={activateCollibra}
                />
              </Descriptions.Item>
            </Descriptions>
          </Row>
        )}
        <Row>
          <Descriptions column={2}>
            <Descriptions.Item
              label="Project name / BEAT ID"
              labelStyle={labelStyle}
              className="descr-item"
            >
              {bucket.project?.name || "-"}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Tooltip
                  title="Data classification"
                  tooltip={<DataClassificationTooltip />}
                />
              }
              labelStyle={labelStyle}
              className="descr-item"
            >
              <Select
                style={selectStyle}
                onChange={handleChangeDataClassification}
                value={
                  currentDataClassification?.label ||
                  bucket.data_classification ||
                  "Not specified"
                }
                loading={loading}
                disabled={
                  !bucket.management_permissions
                    .can_change_data_classification || loading
                }
              >
                {DATA_CLASSIFICATION_LIST.map(
                  ({ id, value, label, isVisible }) =>
                    isVisible ? (
                      <Select.Option
                        disabled={currentDataClassification?.id > id}
                        key={id}
                        value={value}
                      >
                        {label}
                      </Select.Option>
                    ) : null,
                )}
              </Select>
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </Card>
    </>
  );
};

export default memo(General);
