import React, { memo } from "react";

export const AutocompleteItem = memo(
  ({ title, subtitle }: { title: string; subtitle?: string }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{title}</span>
        {subtitle && <span style={{ color: "#8c8c8c" }}>{subtitle}</span>}
      </div>
    );
  },
);
