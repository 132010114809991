import { useEffect, useState } from "react";
import { Button } from "antd";
import Title from "antd/lib/typography/Title";
import Text from "antd/lib/typography/Text";
import Cookies from "js-cookie";

import "./style.scss";

const COOKIE_BANNER = "cookie-banner";

export const CookieBanner = () => {
  const [hasAccept, setHasAccept] = useState(true);
  const [showBanner, setShowBanner] = useState(true);

  const handleAccept = () => {
    Cookies.set(COOKIE_BANNER, "true");
    setShowBanner(false);
  };

  useEffect(() => {
    const hasAccept = Cookies.get(COOKIE_BANNER) === "true";

    setHasAccept(hasAccept);
  }, []);

  return !hasAccept ? (
    <div className={`cookie-banner ${showBanner ? "active" : ""}`}>
      <div className="cookie-banner__container">
        <Title level={5}>Usage of Cookies</Title>
        <Text>
          We use cookies to provide login functionality on our site. Your data
          is not used for any purposes other than authentication. By using our
          site, you agree to the use of cookies. See our{" "}
          <a
            target="_blank"
            href="https://app.datapond.cloud.bayer.com/terms-of-use.pdf"
          >
            Privacy Statement
          </a>{" "}
          for more details.
        </Text>
        <Button
          className="cookie-banner__button"
          type="primary"
          onClick={handleAccept}
        >
          Accept
        </Button>
      </div>
    </div>
  ) : null;
};
