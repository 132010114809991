import { Link } from "react-router-dom";

export const SSHKeysTooltip = () => (
  <>
    It's possible to connect to the bucket over SFTP. For that the Owner can add
    SSH public keys to a static user and then use the private key to connect
    with SFTP client. See more details on the{" "}
    <Link
      to={{
        search: "?tab=Connect",
      }}
    >
      Connectors
    </Link>{" "}
    page.
  </>
);
