import { memo, useCallback, useState } from "react";
import Segmented, { SegmentedValue } from "antd/lib/segmented";
import { IPond } from "pages/Ponds/types";

import { SFTP } from "./components/SFTP";
import PythonConnect from "./components/PythonConnect";
import Science from "./components/Science";
import Athena from "./components/Athena";
import OtherConnectors from "./components/OtherConnectors";

import "./style.scss";

const Connect = ({
  bucket,
  onFetchBucket,
}: {
  bucket: IPond;
  onFetchBucket: () => Promise<unknown>;
}) => {
  const [connectPage, setConnectPage] = useState<SegmentedValue>("Python");

  const changeConnectPages = useCallback((value: SegmentedValue) => {
    setConnectPage(value);
  }, []);

  return (
    <div className="connect-tab">
      <Segmented
        value={connectPage}
        onChange={changeConnectPages}
        size="large"
        options={[
          "Python",
          "Science@Scale",
          "Athena",
          "SFTP",
          "Other connectors",
        ]}
      />
      {connectPage === "Python" ? (
        <PythonConnect bucket={bucket} setConnectPage={setConnectPage} />
      ) : connectPage === "Science@Scale" ? (
        <Science name={bucket.name} />
      ) : connectPage === "Athena" ? (
        <Athena
          bucket={bucket}
          onFetchBucket={onFetchBucket}
          setConnectPage={setConnectPage}
        />
      ) : connectPage === "Other connectors" ? (
        <OtherConnectors />
      ) : (
        <SFTP />
      )}
    </div>
  );
};

export default memo(Connect);
