import { memo, useCallback, useRef, useState } from "react";
import { Alert, Card, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";

import { IPond } from "pages/Ponds/types";
import { CopyOutlined } from "@ant-design/icons";
import { getTextFromCodeBlock, handleCopyText } from "helpers";

const GetAccess = ({
  bucket,
  setConnectPage,
}: {
  bucket: IPond;
  setConnectPage: (value: SegmentedValue) => void;
}) => {
  const [getAccess, setGetAccess] = useState<SegmentedValue>(
    "Example code for Athena at Science@Scale",
  );

  const changeAccessFormat = useCallback((value: SegmentedValue) => {
    setGetAccess(value);
  }, []);

  const switchToPython = useCallback(
    () => setConnectPage("Python"),
    [setConnectPage],
  );

  const codeBlock1 = useRef<HTMLDivElement>(null);
  const codeBlock2 = useRef<HTMLDivElement>(null);
  const codeBlock3 = useRef<HTMLDivElement>(null);
  const codeBlock4 = useRef<HTMLDivElement>(null);
  const codeBlock5 = useRef<HTMLDivElement>(null);
  const codeBlock6 = useRef<HTMLDivElement>(null);

  return (
    <Card
      title="How to get access"
      className="athena-access-block"
      bodyStyle={{
        padding: 0,
      }}
    >
      <div className="athena-alert">
        <Alert
          message="NOTE:"
          description={
            <>
              <div>
                Depending on your S@S image version, you might need to update
                the library to the latest version.
              </div>
              <div>
                In that case running{" "}
                <span className="alert-bold">
                  'pip install --upgrade oauth-keys2'
                </span>{" "}
                in the terminal and restarting Jupyter kernel should be enough.
              </div>
            </>
          }
          type="warning"
          showIcon
        />
      </div>
      <div className="athena-aws-segment">
        <Segmented
          value={getAccess}
          onChange={changeAccessFormat}
          size="large"
          options={[
            "Example code for Athena at Science@Scale",
            "Using Athena out of Science@Scale",
          ]}
        />
      </div>
      {getAccess === "Example code for Athena at Science@Scale" ? (
        <div className="snippet-wrapper">
          <Card title="Example code using the `boto3` library">
            <div className="snippet-block" ref={codeBlock1}>
              <div># Microservice parameters</div>
              <div>import os</div>
              <div className="snippet-mb">import oauth_keys2</div>
              <div>
                os.environ["AUTHZ_API_URL"] = "
                {process.env.REACT_APP_AUTHZ_API_URL}"
              </div>
              <div>
                os.environ["AUTHZ_API_KEY"] = "
                {process.env.REACT_APP_AUTHZ_API_KEY}"
              </div>
              <CopyOutlined
                className="copy-icon block"
                onClick={handleCopyText(
                  "Code",
                  getTextFromCodeBlock(codeBlock1.current),
                )}
              />
            </div>
            <div className="snippet-block" ref={codeBlock2}>
              <div className="snippet-mb">import boto3</div>
              <div># get AWS IAM session</div>
              <div>resp = oauth_keys2.get_aws_keys(</div>
              <div className="snippet-padding">target="iam/user",</div>
              <div className="snippet-mb">)</div>
              <div>
                athena = resp.client("athena", region_name="
                {bucket.params.region}
                ")
              </div>
              <div># Run the query</div>
              <div>queryStart = athena.start_query_execution(</div>
              <div className="snippet-padding">
                QueryString = 'select * from{" "}
                {bucket.name
                  .replace(/[^\w\s]|_/g, "_")
                  .replace(/\s+/g, "_")
                  .toLowerCase()}{" "}
                limit 10',
              </div>
              <div className="snippet-padding">
                QueryExecutionContext = {"{"}
              </div>
              <div className="snippet-padding-plus">
                "Database": "{bucket.params.athena.database}"
              </div>
              <div className="snippet-padding">{"},"}</div>
              <div className="snippet-padding">
                WorkGroup = "{bucket.params.athena.workgroup}"
              </div>
              <div>)</div>
              <div>queryStart</div>
              <CopyOutlined
                className="copy-icon block"
                onClick={handleCopyText(
                  "Code",
                  getTextFromCodeBlock(codeBlock2.current),
                )}
              />
            </div>
            <div className="snippet-block" ref={codeBlock3}>
              <div># Fetch results</div>
              <div className="snippet-mb">import pandas</div>
              <div>queryResult = athena.get_query_results(</div>
              <div className="snippet-padding">
                QueryExecutionId=queryStart['QueryExecutionId']
              </div>
              <div>)</div>
              <div className="snippet-mb">
                pandas.DataFrame([[data.get('VarCharValue') for data in
                row['Data']] for row in queryResult['ResultSet']['Rows']])
              </div>
              <CopyOutlined
                className="copy-icon block"
                onClick={handleCopyText(
                  "Code",
                  getTextFromCodeBlock(codeBlock3.current),
                )}
              />
            </div>
            <div className="snippet-block" ref={codeBlock4}>
              <div># Check execution status</div>
              <div>queryExecution = athena.get_query_execution(</div>
              <div className="snippet-padding">
                QueryExecutionId=queryStart['QueryExecutionId']
              </div>
              <div>)</div>
              <div>queryExecution</div>
              <CopyOutlined
                className="copy-icon block"
                onClick={handleCopyText(
                  "Code",
                  getTextFromCodeBlock(codeBlock4.current),
                )}
              />
            </div>
          </Card>
          <Card title="Example code using the `awswrangler` library">
            <div className="snippet-block" ref={codeBlock5}>
              <div># Microservice parameters</div>
              <div>import os</div>
              <div className="snippet-mb">import oauth_keys2</div>
              <div>
                os.environ["AUTHZ_API_URL"] = "
                {process.env.REACT_APP_AUTHZ_API_URL}"
              </div>
              <div>
                os.environ["AUTHZ_API_KEY"] = "
                {process.env.REACT_APP_AUTHZ_API_KEY}"
              </div>
              <div>
                os.environ["AWS_DEFAULT_REGION"] = "{bucket.params.region}"
              </div>
              <CopyOutlined
                className="copy-icon block"
                onClick={handleCopyText(
                  "Code",
                  getTextFromCodeBlock(codeBlock5.current),
                )}
              />
            </div>
            <div className="snippet-block" ref={codeBlock6}>
              <div>
                # The `awswrangler` library must be installed for this snippet.
              </div>
              <div className="snippet-mb">
                # Use `pip install --upgrade awswrangler` to install.
              </div>
              <div className="snippet-mb">import awswrangler as wr</div>
              <div>boto3_session = oauth_keys2.get_aws_keys(</div>
              <div className="snippet-padding">target="iam/user",</div>
              <div className="snippet-mb">)</div>
              <div>
                df = wr.athena.read_sql_query(sql="select * from{" "}
                {bucket.name
                  .replace(/[^\w\s]|_/g, "_")
                  .replace(/\s+/g, "_")
                  .toLowerCase()}
                ", database="{bucket.params.athena.database}", workgroup="
                {bucket.params.athena.workgroup}", boto3_session=boto3_session)
              </div>
              <div>df</div>
              <CopyOutlined
                className="copy-icon block"
                onClick={handleCopyText(
                  "Code",
                  getTextFromCodeBlock(codeBlock6.current),
                )}
              />
            </div>
          </Card>
        </div>
      ) : (
        <p className="athena-access-descr">
          If you run Athena somewhere other than in Science@Scale, use the keys
          as in{" "}
          <span className="blue-link" onClick={switchToPython}>
            the Python tab.
          </span>
        </p>
      )}
    </Card>
  );
};

export default memo(GetAccess);
