import { useEffect, useContext, useRef, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { History } from "history";
import { Modal } from "antd";
import isEqual from "lodash.isequal";

import { IPond } from "pages/Ponds/types";

type ExtendNavigator = Navigator & Pick<History, "block">;

type IProps = {
  oldPond: IPond;
  changedPond: IPond;
  isSaveButtonClicked: boolean;
};

const DiscardModal = (props: IProps) => {
  const { oldPond, isSaveButtonClicked, changedPond } = props;
  const { navigator } = useContext(NavigationContext);

  const [isModalOpen, setModalOpen] = useState(false);
  const [history, setHistory] = useState("");
  const navigate = useRef(() => {});
  const [isBlock, setBlock] = useState(true);

  useEffect(() => {
    if (!isBlock) {
      navigator.push(history);
    } else {
      if (!isEqual(oldPond, changedPond) && !isSaveButtonClicked) {
        navigate.current = (navigator as unknown as ExtendNavigator).block(
          tx => {
            setHistory(tx.location.pathname);
            setModalOpen(true);
          },
        );
      }
    }

    return () => {
      navigate.current();
    };
  }, [isBlock, history, oldPond, changedPond, navigator, isSaveButtonClicked]);

  useEffect(() => {
    if (isModalOpen) {
      Modal.confirm({
        title: "Unsaved changes",
        content:
          "Are you sure you want to leave? Unsaved changes will be lost.",
        maskClosable: true,
        cancelText: "Keep editing",
        okText: "Close without saving",
        okType: "primary",
        onOk: () => {
          setModalOpen(false);
          setBlock(false);
        },
        onCancel: () => {
          setModalOpen(false);
        },
        cancelButtonProps: {
          type: "default",
        },
      });
    }
  }, [isModalOpen]);

  return null;
};

export default DiscardModal;
