import { CopyOutlined } from "@ant-design/icons";
import { Card, Descriptions } from "antd";
import { SFTP_URL } from "const";
import { handleCopyText } from "helpers";

const labelStyle = {
  width: 170,
  color: "#8c8c8c",
};

export const SFTPPropertiesCard = () => {
  return (
    <Card title="SFTP properties" className="sftp-connect__card">
      <Descriptions column={2}>
        <Descriptions.Item labelStyle={labelStyle} label="SFTP server hostname">
          <div>
            {SFTP_URL}
            <CopyOutlined
              className="copy-icon"
              onClick={handleCopyText("SFTP server hostname", SFTP_URL)}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item labelStyle={labelStyle} label="Auth method">
          SSH keys
        </Descriptions.Item>
        <Descriptions.Item labelStyle={labelStyle} label="Port number">
          22
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
