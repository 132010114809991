export const ColidTooltip = () => (
  <>
    If enabled on creation, the pond is registered as data asset in{" "}
    <a href="https://go.cnb/colid" target="_blank">
      COLID
    </a>{" "}
    data catalog. Note the asset is created in Draft state and requires manual
    publish action in COLID UI.
  </>
);
