import { memo, useCallback, useMemo, useState } from "react";
import {
  Card,
  Collapse,
  Descriptions,
  Popover,
  Row,
  Tooltip as AntTooltip,
} from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";

import Button from "common/Button";
import Label from "common/Label";
import Tooltip from "common/Tooltip";

import { SSHKeyItem } from "./components/SSHKeyItem";
import { SSHKeyModalForm } from "./components/SSHKeyModalForm";
import { formatDateTime, handleCopyText } from "helpers";
import { useMachineUsers } from "./hooks/useMachineUsers";
import { MachineUsers } from "../AccessData/types";
import { SSHKeysTooltip } from "../Tooltips/SSHKeysTooltip";

const MachineUser = ({
  item,
  index,
  loading,
  setLoading,
  id,
  setMachineUsers,
  isOwner,
  onFetchMachineUsers,
}: {
  item: MachineUsers;
  index: number;
  loading: boolean;
  setLoading: (value: string) => void;
  id: string;
  setMachineUsers: (
    value: (prevState: MachineUsers[]) => MachineUsers[] | MachineUsers[],
  ) => void;
  isOwner: boolean;
  onFetchMachineUsers?: () => Promise<unknown>;
}) => {
  const {
    deleteMachineUser,
    deleteSHHKey,
    isDeletingSHHKey,
    submitSSHKey,
    isCreatingSHHKey,
  } = useMachineUsers();

  const [isOpenSSHKeyModal, setIsOpenSSHKeyModal] = useState(false);
  const [collapseKeys, setCollapseKeys] = useState<string | string[]>();
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] =
    useState(false);

  const sshKeys = Object.values(item.ssh_keys || {});

  const showSSHKeys = isOwner && sshKeys.length;

  const hasAccessToSSH = useMemo(
    () => item.permissions?.some(({ prefix }) => prefix === ""),
    [item.permissions],
  );

  const labelStyle = useMemo(
    () => ({
      width: 170,
    }),
    [],
  );

  const openDeleteConfirmation = useCallback(() => {
    setIsOpenDeleteConfirmation(true);
  }, [setIsOpenDeleteConfirmation]);

  const closeDeleteConfirmation = useCallback(() => {
    setIsOpenDeleteConfirmation(false);
  }, [setIsOpenDeleteConfirmation]);

  const openSSHKeyModal = useCallback(() => {
    setIsOpenSSHKeyModal(true);
  }, [setIsOpenSSHKeyModal]);

  const closeSSHKeyModal = useCallback(() => {
    setIsOpenSSHKeyModal(false);
  }, [setIsOpenSSHKeyModal]);

  const handleDeleteMachineUser = useCallback(() => {
    closeDeleteConfirmation();
    deleteMachineUser({
      pondName: id,
      machineUserId: item.id,
      setLoading,
      setMachineUsers,
    });
  }, [
    closeDeleteConfirmation,
    deleteMachineUser,
    id,
    item.id,
    setLoading,
    setMachineUsers,
  ]);

  const handleDeleteSHHKey = useCallback(
    (sshKeyId: string) => {
      deleteSHHKey({
        pondName: id,
        machineUserId: item.id,
        sshKeyId,
        onFetchMachineUsers,
      });
    },
    [deleteSHHKey, id, item.id, onFetchMachineUsers],
  );

  const handleSubmitSSHKey = useCallback(
    (
      DTO: { description: string; public_key: string },
      onCloseForm: () => void,
    ) => {
      submitSSHKey({
        pondName: id,
        machineUserId: item.id,
        onFetchMachineUsers,
        DTO,
        onCloseForm,
      });
    },
    [submitSSHKey, id, item.id, onFetchMachineUsers],
  );

  const collapseChange = useCallback((collapseKey: string | string[]) => {
    setCollapseKeys(collapseKey);
  }, []);

  const MachineUserDeleteConfirmation = useMemo(
    () => (
      <div className="popup">
        <div className="popup-question">
          <ExclamationCircleOutlined />
          <span>Are you sure you want to delete this credentials?</span>
        </div>
        <div className="popup-buttons">
          <Button
            type="default"
            size="small"
            onClick={closeDeleteConfirmation}
            loading={isDeletingSHHKey}
          >
            No
          </Button>
          <Button
            type="primary"
            size="small"
            loading={isDeletingSHHKey}
            onClick={handleDeleteMachineUser}
          >
            Yes
          </Button>
        </div>
      </div>
    ),
    [isDeletingSHHKey, handleDeleteMachineUser, closeDeleteConfirmation],
  );

  return (
    <>
      <Card
        key={index}
        title={item.description}
        className="machine-users-item"
        extra={
          <Popover
            content={MachineUserDeleteConfirmation}
            trigger="click"
            placement="left"
            open={isOpenDeleteConfirmation}
            onOpenChange={openDeleteConfirmation}
          >
            <Button
              danger
              size="large"
              icon={<DeleteOutlined />}
              loading={loading}
            >
              Delete
            </Button>
          </Popover>
        }
      >
        <Row>
          <Descriptions column={2}>
            <Descriptions.Item
              label={<Label name="Expires" />}
              labelStyle={labelStyle}
              className="descr-item"
            >
              {formatDateTime(item.valid_until)}
            </Descriptions.Item>
            <Descriptions.Item
              label={<Label name="Permissions" />}
              labelStyle={labelStyle}
              className="descr-item"
            >
              {item.permissions &&
              item.permissions?.length === 1 &&
              item.permissions[0].prefix === "" ? (
                <div>
                  <span>ALL FOLDERS:</span>
                  <strong style={{ marginLeft: 10 }}>
                    {item.permissions[0].level === "ro"
                      ? "Read only"
                      : item.permissions[0].level === "rw"
                      ? "Read-write"
                      : "Owner"}
                  </strong>
                </div>
              ) : (
                <div>
                  {item.permissions?.map((permission, i) => (
                    <div key={i}>
                      <span>/{permission.prefix}:</span>
                      <strong style={{ marginLeft: 10 }}>
                        {permission.level === "ro"
                          ? "Read only"
                          : permission.level === "rw"
                          ? "Read-write"
                          : "Owner"}
                      </strong>
                    </div>
                  ))}
                </div>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row>
          <Descriptions column={2}>
            <Descriptions.Item
              label={<Label name="Owner" />}
              labelStyle={labelStyle}
              className="descr-item"
            >
              <div>
                {item.created_by}
                <CopyOutlined
                  className="copy-icon"
                  onClick={handleCopyText("Email", item.created_by)}
                />
              </div>
            </Descriptions.Item>
            <Descriptions.Item
              label={<Label name="Access Key ID" />}
              labelStyle={labelStyle}
              className="descr-item"
            >
              <div>
                {item.access_key_id}
                <CopyOutlined
                  className="copy-icon"
                  onClick={handleCopyText("Access Key ID", item.access_key_id)}
                />
              </div>
            </Descriptions.Item>
          </Descriptions>
        </Row>
        {showSSHKeys ? (
          <Collapse
            className="block-collapse ssh-collapse"
            onChange={collapseChange}
            expandIconPosition="end"
          >
            <Collapse.Panel
              className="block-collapse-panel"
              header={<Tooltip title="SSH keys" tooltip={<SSHKeysTooltip />} />}
              key="1"
              extra={
                collapseKeys?.length ? (
                  <Button type="default" icon={<UpOutlined />} size="middle">
                    Collapse
                  </Button>
                ) : (
                  <Button type="default" icon={<DownOutlined />} size="middle">
                    Expand
                  </Button>
                )
              }
            >
              {showSSHKeys
                ? sshKeys?.map(sshKeyItem => (
                    <SSHKeyItem
                      key={sshKeyItem.id}
                      item={sshKeyItem}
                      loading={loading}
                      isDeletingSHHKey={isDeletingSHHKey}
                      onDeleteSHHKey={handleDeleteSHHKey}
                    />
                  ))
                : null}
            </Collapse.Panel>
          </Collapse>
        ) : null}
        {isOwner && (
          <AntTooltip title={showSSHKeys ? null : <SSHKeysTooltip />}>
            <Button
              type="dashed"
              className="add-permissions-btn"
              icon={<PlusOutlined />}
              onClick={openSSHKeyModal}
              loading={loading}
            >
              Add SSH key
            </Button>
          </AntTooltip>
        )}
      </Card>
      <SSHKeyModalForm
        open={isOpenSSHKeyModal}
        onCancel={closeSSHKeyModal}
        onSubmitSSHKey={handleSubmitSSHKey}
        isCreatingSHHKey={isCreatingSHHKey}
        showAlert={!hasAccessToSSH}
      />
    </>
  );
};

export default memo(MachineUser);
