import { useCallback, useState } from "react";
import { Collapse as AntCollapse } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import Button from "common/Button";

type Props = {
  classNameComponent?: string;
  classNameBody?: string;
  defaultCollapsed?: string | string[];
  hideButton?: boolean;
  items: {
    title: string;
    body: JSX.Element;
    buttonSize?: SizeType;
  }[];
};

export const Collapse = ({
  classNameComponent,
  classNameBody,
  defaultCollapsed = [],
  hideButton = false,
  items,
}: Props) => {
  const [collapseKeys, setCollapseKeys] = useState<string | string[]>(
    defaultCollapsed,
  );

  const collapseChange = useCallback((collapseKey: string | string[]) => {
    setCollapseKeys(collapseKey);
  }, []);

  return (
    <AntCollapse
      className={`block-collapse ${classNameComponent}`}
      onChange={collapseChange}
      expandIconPosition="end"
      defaultActiveKey={defaultCollapsed}
    >
      {items.map(({ title, body, buttonSize = "middle" }, idx) => (
        <AntCollapse.Panel
          className={`block-collapse-panel ${classNameBody}`}
          header={title}
          key={`${idx}`}
          extra={
            hideButton ? null : collapseKeys.includes(`${idx}`) ? (
              <Button
                type="default"
                style={{ width: 116 }}
                icon={<UpOutlined />}
                size={buttonSize}
              >
                Collapse
              </Button>
            ) : (
              <Button
                type="default"
                style={{ width: 116 }}
                icon={<DownOutlined />}
                size={buttonSize}
              >
                Expand
              </Button>
            )
          }
        >
          {body}
        </AntCollapse.Panel>
      ))}
    </AntCollapse>
  );
};
