import { memo, useCallback, useState } from "react";
import { Collapse, Descriptions, Row, Segmented } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  LinkOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { SegmentedValue } from "antd/lib/segmented";

import Button from "common/Button";

const AboutAthena = () => {
  const [about, setAbout] = useState<SegmentedValue>("About Athena");
  const [collapseKeys, setCollapseKeys] = useState<string | string[]>(["1"]);

  const changeAboutSegment = useCallback((value: SegmentedValue) => {
    setAbout(value);
  }, []);

  const collapseChange = useCallback((collapseKey: string | string[]) => {
    setCollapseKeys(collapseKey);
  }, []);

  return (
    <Collapse
      className="block-collapse"
      expandIconPosition="end"
      defaultActiveKey={["1"]}
      onChange={collapseChange}
    >
      <Collapse.Panel
        className="block-collapse-panel"
        header="About AWS Athena"
        key="1"
        extra={
          collapseKeys?.length ? (
            <Button type="default" icon={<UpOutlined />} size="large">
              Collapse
            </Button>
          ) : (
            <Button type="default" icon={<DownOutlined />} size="large">
              Expand
            </Button>
          )
        }
      >
        <div className="athena-aws-segment">
          <Segmented
            value={about}
            onChange={changeAboutSegment}
            size="large"
            options={["About Athena", "User guides"]}
          />
        </div>
        {about === "About Athena" ? (
          <>
            <div className="descr">
              Amazon Athena is an interactive query service that makes it easy
              to analyze data directly in Amazon S3 using standard SQL. With a
              few clicks in the Amazon Web Services Management Console,
              customers can point Athena at their data stored in S3 and begin
              using standard SQL to run ad-hoc queries and get results in
              seconds:
            </div>
            <div className="lists">
              <Row>
                <Descriptions column={2}>
                  <Descriptions.Item>
                    <div className="check">
                      <CheckOutlined />
                    </div>
                    creating a unified metadata repository across various
                    services
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <div className="reject">
                      <CloseOutlined />
                    </div>
                    you need knowledge of code
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={2}>
                  <Descriptions.Item>
                    <div className="check">
                      <CheckOutlined />
                    </div>
                    crawling data sources to discover schemas
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <div className="reject">
                      <CloseOutlined />
                    </div>
                    to gain access, you need obtain access keys (the instruction
                    is below)
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="check">
                      <CheckOutlined />
                    </div>
                    populating your Catalog with new and modified table and
                    partition definitions
                  </Descriptions.Item>
                </Descriptions>
              </Row>
              <Row>
                <Descriptions column={1}>
                  <Descriptions.Item>
                    <div className="check">
                      <CheckOutlined />
                    </div>
                    maintaining schema versioning
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            </div>
          </>
        ) : (
          <div className="links">
            <div className="links-item">
              <LinkOutlined />
              <a
                href="https://aws.amazon.com/athena/features/?nc=sn&loc=2"
                target="_blank"
              >
                AWS Athena documentation
              </a>
            </div>
            <div className="links-item">
              <LinkOutlined />
              <a
                href="https://data-at-scale.docs.cloud.bayer.com/modules/datapond-api/docs/user_guide#programmatic-access-to-to-the-pond"
                target="_blank"
              >
                Programmatic access to the pond
              </a>
            </div>
            <div className="links-item">
              <LinkOutlined />
              <a
                href="https://data-at-scale.docs.cloud.bayer.com/modules/datapond-api/docs/user_guide#athena"
                target="_blank"
              >
                Querying data with AWS Athena
              </a>
            </div>
            <div className="links-item">
              <LinkOutlined />
              <a
                href="https://data-at-scale.docs.cloud.bayer.com/modules/datapond-api/docs/user_guide#athena-tableau"
                target="_blank"
              >
                Connecting with Tableau from Bayer network
              </a>
            </div>
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default memo(AboutAthena);
