import { useCallback, useEffect, useReducer, useRef } from "react";
import { IMsalContext } from "@azure/msal-react";
import { getAccessToken } from "../config/token";
import { $bucketUrlHost } from "../http";
import { getHeaders } from "../helpers";
import { AxiosError } from "axios";

export interface State<T> {
  data?: T;
  error?: AxiosError;
  isLoading: boolean;
  token?: string;
  fetchData: () => Promise<unknown>;
}

// discriminated union type
type Action<T> =
  | { type: "loading" }
  | { type: "fetched"; payload: T }
  | { type: "error"; payload: AxiosError }
  | { type: "expired"; payload: string };

function useFetch<T = unknown>(
  url?: string,
  msalInstance?: IMsalContext,
): State<T> {
  let msl: IMsalContext;

  if (msalInstance) {
    msl = msalInstance;
  }

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false);

  const initialState: State<T> = {
    error: undefined,
    data: undefined,
    isLoading: false,
    token: undefined,
    fetchData: () => Promise.resolve(),
  };

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState, isLoading: true };
      case "fetched":
        return { ...initialState, data: action.payload, isLoading: false };
      case "error":
        return { ...initialState, error: action.payload, isLoading: false };
      case "expired":
        return { ...initialState, token: action.payload, isLoading: false };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const fetchData = useCallback(
    async (showLoading = false) => {
      if (!url) return Promise.resolve();

      const tokenData = await getAccessToken(msl);
      showLoading && dispatch({ type: "loading" });

      if (tokenData?.expiresOn) {
        const expiresAt = parseInt(
          (tokenData.expiresOn.getTime() / 1000).toString(),
        );
        const time = parseInt((new Date().getTime() / 1000).toString());

        if (expiresAt <= time) {
          dispatch({ type: "expired", payload: "token expired" });
          return Promise.resolve();
        }
      }

      if (tokenData?.idTokenClaims) {
        const time = parseInt((new Date().getTime() / 1000).toString());

        if (tokenData.idTokenClaims <= time) {
          dispatch({ type: "expired", payload: "token expired" });
          return Promise.resolve();
        }
      }

      return $bucketUrlHost(url, {
        headers: getHeaders(tokenData?.token),
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }

          if (cancelRequest.current) return;

          dispatch({ type: "fetched", payload: response.data });
        })
        .catch(error => {
          if (cancelRequest.current) return;

          dispatch({ type: "error", payload: error as AxiosError });
        });
    },
    [url, dispatch],
  );

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return;

    (async () => {
      cancelRequest.current = false;

      await fetchData(true);

      void fetchData();
    })();

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { ...state, fetchData };
}

export default useFetch;
