import { useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import useFetch from "hooks/useFetch";
import { bucketUrl } from "http/index";

export const useRegions = () => {
  const msalInstance = useMsal();

  const [regions, setRegions] = useState<string[]>([]);

  const { data, isLoading } = useFetch<string[]>(
    `${bucketUrl}v2/s3buckets/settings/regions`,
    msalInstance,
  );

  useEffect(() => {
    if (data && !isLoading) {
      setRegions(data);
    }
  }, [data, isLoading]);

  return useMemo(
    () => ({
      regions,
      isLoading,
    }),
    [regions, isLoading],
  );
};
