import { memo, useEffect, useMemo } from "react";

import { Card, Input, Select, Checkbox, Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import Tooltip from "common/Tooltip";

import {
  Colid,
  EClassification,
  ELifeCycleStatus,
  IPond,
} from "pages/Ponds/types";

type IProps = {
  colid: Colid;
  data: IPond;
  segment: SegmentedValue;
  onSetData: (value: IPond) => void;
  onChangeColidField: (
    name: string,
    value: string | boolean | ELifeCycleStatus | EClassification,
  ) => void;
  onSetSegment: (value: SegmentedValue) => void;
};

const AddPondColid = ({
  colid,
  data,
  segment,
  onSetData,
  onChangeColidField,
  onSetSegment,
}: IProps) => {
  useEffect(() => {
    onSetData({
      ...data,
      params: {
        ...data.params,
        colid:
          colid?.definition && colid.label
            ? {
                ...data.params.colid,
                label: `Data pond S3 bucket ${data.name}`,
                definition: `Data pond S3 bucket ${data.name}`,
              }
            : null,
      },
    });
  }, [data.name]);

  const handleChangeSegment = (value: SegmentedValue) => onSetSegment(value);

  const handleChangeColidInputField = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChangeColidField(event.target.name, event.target.value);
  };

  const handleChangeCheckBox = (event: CheckboxChangeEvent) => {
    event.target.name &&
      onChangeColidField(event.target.name, event.target.checked);
  };

  const classificationOptions = useMemo(
    () =>
      Object.values(EClassification).map(value => (
        <Select.Option key={value} value={value}>
          {value}
        </Select.Option>
      )),
    [],
  );

  const lifeCycleStatusOptions = useMemo(
    () =>
      Object.values(ELifeCycleStatus).map(value => (
        <Select.Option key={value} value={value}>
          {value}
        </Select.Option>
      )),
    [],
  );

  const handleChangeSelect = (
    name: string,
    value: ELifeCycleStatus | EClassification,
  ) => {
    onChangeColidField(name, value);
  };

  return colid ? (
    <Card
      title="COLID information"
      bordered={false}
      bodyStyle={{
        color: "rgba(0, 0, 0, 0.65)",
        padding: 0,
      }}
      className="colid-card"
    >
      <div className="colid-header">
        <Segmented
          options={["New asset", "Existing asset"]}
          value={segment}
          onChange={handleChangeSegment}
        />
      </div>
      <div className="colid-body">
        {segment === "Existing asset" ? (
          <>
            <Tooltip
              title="PID URI"
              tooltip="PID URI is a link, which leads directly to the catalog entry"
            />
            <Input
              onChange={handleChangeColidInputField}
              value={colid.pid_uri}
              name="pid_uri"
              placeholder="example"
              className="pId-input"
            />
          </>
        ) : (
          <>
            <div className="first-line">
              <div>
                <p>Label</p>
                <Input
                  onChange={handleChangeColidInputField}
                  name="label"
                  value={colid.label}
                />
              </div>
              <div>
                <p>Definition</p>
                <Input
                  onChange={handleChangeColidInputField}
                  name="definition"
                  value={colid.definition}
                />
              </div>
              <div>
                <p>Lifecycle Status</p>
                <Select
                  className="select-colid"
                  onChange={(value: EClassification) =>
                    handleChangeSelect("information_classification", value)
                  }
                  value={colid.information_classification}
                >
                  {classificationOptions}
                </Select>
              </div>
            </div>
            <div className="second-line">
              <div>
                <p>Classification</p>
                <div className="fields">
                  <Select
                    className="select-colid"
                    value={colid.lifecycle_status}
                    onChange={(value: ELifeCycleStatus) =>
                      handleChangeSelect("lifecycle_status", value)
                    }
                  >
                    {lifeCycleStatusOptions}
                  </Select>
                  <Checkbox
                    onChange={handleChangeCheckBox}
                    checked={!!colid.licensed_data}
                    className="agree-terms"
                    name="licensed_data"
                  >
                    Licensed Data
                  </Checkbox>
                  <Checkbox
                    onChange={handleChangeCheckBox}
                    name="personal_data"
                    checked={!!colid.personal_data}
                    className="agree-terms"
                  >
                    Personal Data
                  </Checkbox>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Card>
  ) : null;
};

export default memo(AddPondColid);
