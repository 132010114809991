import { IMsalContext } from "@azure/msal-react";
import { getAccessToken } from "config/token";
import { getHeaders } from "helpers";
import { $bucketUrlHost } from "../../http";
import { useCallback, useMemo, useRef, useState } from "react";
import { isDevelopment } from "const";

type IProps = {
  msalInstance: IMsalContext;
  api: string;
  limit?: number;
};

type ResponseData = {
  id: string;
  name: string;
  mail?: string;
};

const REQUEST_COUNT = 10;
const TIME_DELAY = 500;

export const useSearchAD = ({ msalInstance, api, limit = 20 }: IProps) => {
  const [results, setResults] = useState<ResponseData[]>([]);
  const [loadingSet, setLoadingSet] = useState<Set<string>>(new Set());

  const loadersSet = useRef<Set<string>>(new Set());

  const handleSearch = useCallback(
    async (
      value: string,
      {
        id = "",
        requestCount = 0,
      }: {
        id?: string;
        requestCount?: number;
      },
    ) => {
      if (!value) {
        setResults([]);
        return;
      }

      loadersSet.current.add(id);

      setLoadingSet(new Set(loadersSet.current));

      const token = await getAccessToken(msalInstance);

      if (!token && !isDevelopment) return;

      $bucketUrlHost
        .get(`${api}?q=${value}&limit=${limit}`, {
          headers: getHeaders(token?.token),
        })
        .then(({ data }) => setResults(data))
        .catch(error => {
          if (requestCount >= REQUEST_COUNT) return;

          const param = new URL(error.request.responseURL).searchParams.get(
            "q",
          );

          setTimeout(
            () =>
              handleSearch(param || "", {
                id,
                requestCount: requestCount + 1,
              }),
            TIME_DELAY,
          );
        })
        .finally(() => {
          loadersSet.current.delete(id);
          setLoadingSet(new Set(loadersSet.current));
        });
    },
    [msalInstance, api, limit, setLoadingSet],
  );

  return useMemo(
    () => ({ results, loadingSet, handleSearch }),
    [results, loadingSet, handleSearch],
  );
};
