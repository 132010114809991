import { useCallback, useState } from "react";
import { Segmented } from "antd";
import { SegmentedValue } from "antd/lib/segmented";

import { IPond } from "pages/Ponds/types";

import { AuditLog } from "./AuditLog";
import { AccessLog } from "./AccessLog";

import "./style.scss";

type Props = {
  bucketName: string;
  permissions?: IPond["management_permissions"];
};

enum TittleMenu {
  AUDIT_LOG = "Audit log",
  ACCESS_LOG = "Access log",
}

export const Logs = ({ bucketName, permissions }: Props) => {
  const [logsPage, setLogsPage] = useState<SegmentedValue>(
    TittleMenu.AUDIT_LOG,
  );

  const changeConnectPages = useCallback(
    (value: SegmentedValue) => {
      setLogsPage(value);
    },
    [setLogsPage],
  );

  return (
    <div className="logs-container">
      {permissions?.can_read_audit_logs && (
        <Segmented
          value={logsPage}
          onChange={changeConnectPages}
          size="large"
          options={Object.values(TittleMenu)}
          style={{ marginBottom: 24 }}
        />
      )}
      {logsPage === TittleMenu.AUDIT_LOG && (
        <AuditLog bucketName={bucketName} />
      )}
      {logsPage === TittleMenu.ACCESS_LOG && (
        <AccessLog bucketName={bucketName} />
      )}
    </div>
  );
};
