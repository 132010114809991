import { useCallback, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { message } from "antd";

import { dateToISO, subtractDaysFromDate } from "helpers";
import { useLogin } from "hooks/useLogin";
import { getAccessLog } from "http/userApi";
import { IAccessLog } from "../AccessLog/types";

export const useAccessLogs = () => {
  const msalInstance = useMsal();

  const { showAuthorizationErrorModal } = useLogin(msalInstance);

  const [accessLog, setAccessLog] = useState<IAccessLog | null>(null);
  const [loading, setLoading] = useState(false);

  const getAccessLogs = useCallback(
    async (pondName: string, days: number) => {
      setLoading(true);

      const dateTo = new Date();
      const dateFrom = subtractDaysFromDate(dateTo, days);

      const result: {
        data: IAccessLog | null;
        error: null | string;
      } = await getAccessLog(
        pondName,
        dateToISO(dateFrom),
        dateToISO(dateTo),
        msalInstance,
      );

      if (result.error === "token expired") {
        return showAuthorizationErrorModal();
      }

      if (result.data) {
        setAccessLog(result.data);
      }

      if (result.error) {
        message.error({
          content: result.error,
          duration: 2,
        });
      }

      setLoading(false);
    },
    [msalInstance, setAccessLog, setLoading, showAuthorizationErrorModal],
  );

  return useMemo(
    () => ({ accessLog, loading, getAccessLogs }),
    [accessLog, loading, getAccessLogs],
  );
};
