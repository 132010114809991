export const ArchiveTooltip = () => (
  <>
    When enabled, files that are inactive longer than 6 months will be moved to{" "}
    <a
      href="https://aws.amazon.com/s3/storage-classes/glacier/"
      target="_blank"
    >
      S3 Glacier Deep Archive
    </a>{" "}
    storage tier and will require restore request before reading them.
  </>
);
