export const AthenaTooltip = () => (
  <>
    It's possible to enable{" "}
    <a
      href="https://docs.aws.amazon.com/athena/latest/ug/what-is.html"
      target="_blank"
    >
      AWS Athena
    </a>{" "}
    queries on the files in the Pond. See{" "}
    <a
      href="https://data-at-scale.docs.cloud.bayer.com/modules/datapond-api/docs/user_guide/#connect"
      target="_blank"
    >
      user guide
    </a>{" "}
    for more details.
  </>
);
