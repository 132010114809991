export const aboutSFTPCollapseItems = [
  {
    title: "About SFTP",
    body: (
      <div className="sftp-connect__container">
        <p>
          The Secure File Transfer Protocol (
          <a
            href="https://en.wikipedia.org/wiki/SSH_File_Transfer_Protocol"
            target="_blank"
          >
            SFTP
          </a>
          ) is a network protocol used for secure and encrypted file transfer
          over public networks. It's an extension of the SSH (Secure Shell)
          protocol and is commonly employed for securely transferring files
          between. SFTP ensures the confidentiality, integrity, and authenticity
          of data during transit. To establish an SFTP connection, users
          typically authenticate themselves using SSH keys. Once connected, they
          can perform a range of file operations, including uploading,
          downloading, renaming, and deleting files and directories.
        </p>
        <br />
        <p>
          In Data Pond the SFTP protocol can be used as an alternative to native
          S3 API to access the files in pond's underlying S3 bucket. We
          currently allow SFTP only for static users, not human ones. Users
          always have the same permissions on the files in the buckets
          regardless of the connection method. In order to connect to the pond
          over SFTP, the Data Owner must add a static user to the pond, generate
          private-public SSH key pair and add the public key to the static user.
          After that it's possible to connect over SFTP using the Secret Key ID
          of the static user as a user name and the secret key.
        </p>
      </div>
    ),
  },
];
