import React, { memo } from "react";
import { Typography } from "antd";

import "./style.scss";

const { Title } = Typography;

const Main = () => {
  return (
    <div className="main">
      <Title level={1} className="main-title">
        Object storage with easy access
      </Title>
      <div className="main-subtitle">
        Create, populate and share S3 buckets with colleagues
      </div>
      <p className="main-descr">
        Provision a bucket, add data to it and give other people in Bayer access
        to it in less than 5 minutes without entering any credentials anywhere!
      </p>
    </div>
  );
};

export default memo(Main);
